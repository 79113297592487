import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 931 887">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd">
					<path fill="#FFFFFF" opacity="1.000000" stroke="none"
						d="
M530.000000,888.000000 
	C353.333344,888.000000 177.166672,888.000000 1.000000,888.000000 
	C1.000000,592.333313 1.000000,296.666656 1.000000,1.000000 
	C311.333344,1.000000 621.666687,1.000000 932.000000,1.000000 
	C932.000000,296.666656 932.000000,592.333313 932.000000,888.000000 
	C798.166687,888.000000 664.333313,888.000000 530.000000,888.000000 
M388.575806,252.142563 
	C388.101868,252.546310 387.627899,252.950058 386.863251,254.002762 
	C386.585449,256.657257 386.307648,259.311737 386.072052,262.697662 
	C386.869873,264.280396 387.396912,266.102325 388.531982,267.387085 
	C390.051147,269.106567 392.041473,270.409882 393.409119,272.182678 
	C393.195831,272.448425 392.982574,272.714172 392.034790,273.191711 
	C391.483307,279.997528 390.291077,286.835938 390.634583,293.596283 
	C390.823059,297.305817 389.776245,299.055817 387.142944,300.905548 
	C385.855377,301.809998 385.009155,303.342651 383.102081,304.739929 
	C381.068390,304.847687 379.034668,304.955444 376.132416,304.935486 
	C374.089539,304.996277 372.046631,305.057037 369.478210,304.955109 
	C369.305328,305.016327 369.132477,305.077545 369.055481,304.927399 
	C369.055481,304.927399 369.086761,304.697418 369.025635,303.980988 
	C366.934357,302.662476 364.945709,300.462708 362.730927,300.204803 
	C357.780945,299.628357 352.718048,299.989899 347.701569,300.027740 
	C344.372681,300.052826 343.005768,301.952484 343.007843,305.123413 
	C343.019745,323.359100 343.037903,341.594849 342.995972,359.830414 
	C342.989319,362.712921 344.017548,364.277222 347.558868,364.007355 
	C347.558868,364.007355 347.960876,364.245392 348.075043,364.936981 
	C349.347382,367.600403 351.204712,368.324738 354.176636,367.534027 
	C356.517578,366.911163 359.239563,366.616760 361.535492,367.195435 
	C364.720001,367.998108 365.910828,366.868195 367.240692,364.238831 
	C368.257751,362.227966 370.229156,359.338440 371.904999,359.248749 
	C381.047241,358.759430 390.229431,359.015900 399.732117,359.015900 
	C400.199860,367.329254 401.936066,374.455505 410.421143,377.401794 
	C410.562164,377.450775 410.639679,377.661407 410.778687,377.740601 
	C414.405853,379.806030 417.888702,383.251160 421.696259,383.659058 
	C432.445251,384.810516 443.579529,386.408417 453.669861,380.694855 
	C456.226685,379.247070 457.870331,376.186523 460.145996,373.448212 
	C460.341583,373.180908 460.602692,373.019958 461.703705,372.999939 
	C462.729889,371.603760 463.756073,370.207611 464.906158,368.790161 
	C464.906158,368.790161 464.774475,368.790527 465.486755,368.843994 
	C469.258270,364.230438 473.095123,359.666718 476.706299,354.930908 
	C477.092987,354.423828 476.326996,352.825195 475.816193,351.884735 
	C473.981903,348.507446 475.729340,346.656464 478.704681,346.127960 
	C484.192932,345.153076 489.785522,344.777618 495.296265,343.908691 
	C496.593262,343.704163 497.737427,342.530182 499.760559,341.688690 
	C500.833893,341.829224 501.907227,341.969757 503.117920,342.813171 
	C504.029968,343.474487 504.946411,344.715820 505.853394,344.708954 
	C517.063721,344.623962 521.664978,354.347015 528.917114,360.099884 
	C529.387085,360.472656 529.735168,361.246765 529.785767,361.863220 
	C530.555115,371.242188 531.369263,380.619904 531.921936,390.012665 
	C532.074768,392.610199 531.419006,395.257019 531.115295,397.879059 
	C530.889465,399.829041 531.107178,402.002197 530.303894,403.682190 
	C527.575378,409.388611 529.208740,415.619080 534.818481,418.442474 
	C537.785400,419.935730 541.024719,420.887573 544.657410,422.282074 
	C544.859619,423.185425 545.226379,424.824493 545.739746,427.118439 
	C548.266907,420.509766 551.569031,418.912476 555.859741,422.008728 
	C553.966064,425.669861 551.895325,429.252106 550.220459,433.010712 
	C547.735474,438.587280 543.696045,443.661865 545.084167,450.510376 
	C546.052612,455.288269 549.836304,459.430573 554.565247,459.897308 
	C559.307251,460.365417 563.811646,459.520813 566.346436,455.199707 
	C569.122498,450.467194 569.703064,445.272644 567.050110,440.073853 
	C564.127075,434.345917 561.286682,428.575836 559.246155,422.834564 
	C562.899780,422.217682 566.621887,421.853210 570.180603,420.886627 
	C572.227600,420.330658 574.009888,418.799835 576.318970,417.441650 
	C576.755920,417.227203 577.192871,417.012756 578.466736,416.906891 
	C583.354675,417.471558 582.750549,413.138123 583.314758,410.656464 
	C583.591736,409.438049 581.115845,407.593781 579.561829,405.752014 
	C579.353394,405.556763 579.152466,405.354034 578.942322,404.441742 
	C578.656067,403.106995 578.369812,401.772278 578.501221,400.164154 
	C578.501221,400.164154 578.964661,399.978546 579.633362,400.265259 
	C580.413330,399.660645 581.884766,399.040649 581.871704,398.453705 
	C581.591858,385.881134 581.253784,373.307037 580.640015,360.747223 
	C580.517944,358.249695 579.078552,355.842316 578.487488,353.336578 
	C577.244141,348.065582 574.881531,342.634979 575.371094,337.497925 
	C575.860779,332.359070 573.156677,329.901886 570.608582,326.956879 
	C569.353394,325.506104 567.210083,324.823761 565.347534,323.624603 
	C565.347534,323.624603 565.244995,323.443665 565.646057,322.761261 
	C564.750000,322.181183 563.854004,321.601135 562.423828,320.790680 
	C561.944092,320.200684 561.464355,319.610687 561.012878,318.245087 
	C559.827209,311.827576 553.635315,310.527527 549.300110,308.424438 
	C543.839478,305.775421 537.269531,305.014221 531.082153,304.388245 
	C526.117981,303.886017 521.010620,304.799408 515.312866,305.157440 
	C514.877808,305.110779 514.442749,305.064117 513.847717,304.673615 
	C513.559937,304.761139 513.272156,304.848633 512.161621,305.001373 
	C510.790680,304.940796 509.419739,304.880219 507.917694,304.202637 
	C506.926086,304.379730 505.934448,304.556824 504.019379,304.841217 
	C496.701263,304.794373 489.383148,304.747498 481.910034,304.017639 
	C478.971771,301.419006 469.511475,300.608276 466.492432,302.797180 
	C466.077301,303.098175 466.099396,304.002167 465.216461,304.760284 
	C464.506561,304.711304 463.796631,304.662354 463.021362,303.908691 
	C461.900696,303.030853 460.465240,301.187378 459.710999,301.433289 
	C455.137726,302.924286 454.860443,300.198364 454.989838,297.042755 
	C455.351807,288.213013 455.799255,279.386780 456.218048,270.451569 
	C453.903992,270.093414 451.491730,269.720032 449.079468,269.346680 
	C449.102020,268.638611 449.124542,267.930573 449.147095,267.222534 
	C452.044769,267.144257 455.029602,267.465698 457.812195,266.859253 
	C459.978424,266.387085 462.516937,265.221008 463.765991,263.532074 
	C465.440430,261.267975 465.774353,258.234802 462.744171,255.707428 
	C462.744171,255.707428 462.759827,255.345612 463.107483,254.672577 
	C463.158966,253.931992 463.210419,253.191406 463.261871,252.450836 
	C462.343048,252.512756 461.424225,252.574692 460.486572,252.523407 
	C460.486572,252.523407 460.371948,252.528366 460.759460,251.671539 
	C459.742065,250.782974 458.791351,249.240250 457.694733,249.128403 
	C454.396637,248.792038 451.040375,249.070175 447.709320,248.995926 
	C445.653748,248.950104 443.602722,248.698700 440.894409,248.488815 
	C447.669525,245.586472 453.471344,243.101059 459.653717,240.452637 
	C459.055786,239.245132 458.496094,238.114868 457.911133,236.321701 
	C457.951569,235.881805 457.992004,235.441925 458.703735,234.998779 
	C459.295837,234.596298 460.455322,234.043030 460.394470,233.814056 
	C459.551147,230.640762 452.547150,226.110855 449.184845,226.425476 
	C444.905304,226.825928 440.126892,224.113159 440.093506,220.796051 
	C440.051300,216.602158 440.648376,212.401810 440.902771,209.078842 
	C447.866699,206.917496 453.538513,204.539490 459.446259,203.490860 
	C465.834778,202.356888 472.477722,202.747467 478.949036,201.968872 
	C490.999695,200.518997 493.438995,197.775986 495.274200,185.822220 
	C495.895386,181.775757 494.111664,180.140106 490.632050,179.358536 
	C490.632050,179.358536 490.562714,179.327087 490.839844,178.554459 
	C489.993408,176.851181 489.575073,174.225250 488.228241,173.600266 
	C481.484955,170.471146 474.455872,167.961044 467.031647,171.565689 
	C463.619629,173.222305 460.345581,175.163025 456.556946,176.921432 
	C456.556946,176.921432 456.110413,176.833267 455.941193,176.145660 
	C454.299133,174.400024 452.975983,171.822281 450.960907,171.050018 
	C441.263763,167.333679 431.331726,164.230759 420.728302,165.086395 
	C418.352417,165.278107 415.898407,167.156387 413.730865,166.787262 
	C406.643951,165.580429 400.931458,168.841400 395.049835,171.635162 
	C390.718933,173.692337 386.591919,178.887787 382.186371,171.815536 
	C382.105103,171.685120 381.912109,171.520477 381.789795,171.534409 
	C376.478485,172.139252 372.156677,164.402084 366.239655,169.670425 
	C365.947174,169.930832 364.991821,169.315338 364.354340,169.340546 
	C359.251556,169.542282 354.319092,175.327606 355.587738,179.943253 
	C355.587738,179.943253 355.438904,180.187622 354.617096,180.155518 
	C350.231415,185.860855 352.472809,198.245956 358.890472,200.624466 
	C365.775177,203.176071 372.778168,206.393463 380.600433,202.844452 
	C382.588776,201.942322 385.634247,202.780792 388.014343,203.454224 
	C392.940765,204.848160 397.710022,206.796509 402.634308,208.199768 
	C403.560852,208.463806 404.907593,207.253311 406.720734,206.736847 
	C407.147217,206.842010 407.573730,206.947159 408.338837,207.695221 
	C412.611053,211.132004 410.534180,215.929260 411.017090,220.179184 
	C411.373444,223.315338 409.699982,224.322754 406.874695,225.138382 
	C404.728607,225.757904 402.976013,227.740509 400.646851,229.530991 
	C400.104584,229.711990 399.562286,229.892990 398.618225,229.529297 
	C397.733307,230.327011 396.848419,231.124710 395.715240,232.486649 
	C395.110596,233.319839 394.505920,234.153030 393.207642,235.128815 
	C393.127228,236.683273 393.046814,238.237747 392.966400,239.792206 
	C393.989594,239.840027 395.012787,239.887848 396.601135,240.048355 
	C397.066803,240.366104 397.532501,240.683868 397.996643,241.765884 
	C398.142456,243.973831 397.925018,245.854050 401.544739,245.511642 
	C404.485870,245.233459 407.592285,246.703003 410.625397,247.397461 
	C410.569000,247.939743 410.512604,248.482025 410.456207,249.024307 
	C408.402496,249.024307 406.287476,248.726746 404.317291,249.132507 
	C403.075409,249.388275 402.050293,250.696350 400.091583,251.751312 
	C398.082581,251.685562 396.073547,251.619812 394.031616,250.862045 
	C393.099121,248.932129 392.166626,247.002228 391.234161,245.072311 
	C390.600128,245.203812 389.966125,245.335297 389.332092,245.466782 
	C389.332092,247.763306 389.332092,250.059845 389.311890,252.287598 
	C389.311890,252.287598 389.258179,252.335068 388.575806,252.142563 
M404.367859,673.197449 
	C403.939056,673.372375 403.510254,673.547302 402.207245,673.512634 
	C392.998749,674.040100 390.358948,671.664795 390.307129,662.610413 
	C390.250885,652.780090 390.421051,642.946899 390.253021,633.119080 
	C390.007111,618.736755 391.039948,621.221130 401.686981,621.438354 
	C406.521606,621.536987 408.102722,620.005188 407.980804,615.209473 
	C407.768097,606.842896 408.012421,606.841248 399.405518,607.237427 
	C398.407898,607.283325 397.400787,607.176208 396.407684,607.257385 
	C392.066986,607.612366 389.984406,606.111267 390.240326,601.295898 
	C390.599396,594.539001 390.331024,587.748779 390.331024,580.378662 
	C385.306854,580.378662 381.002930,580.561707 376.722778,580.321350 
	C373.232330,580.125366 372.432800,581.808716 372.498077,584.824036 
	C372.617004,590.320190 372.415344,595.825989 372.633118,601.316345 
	C372.787201,605.202637 371.724701,607.169189 367.358002,607.030701 
	C365.666962,606.977173 362.642395,608.440491 362.436615,609.603882 
	C361.791656,613.250183 362.199524,617.082642 362.199524,620.882446 
	C363.478485,621.078979 364.120056,621.241272 364.767120,621.267334 
	C372.641052,621.583618 372.650177,621.578369 372.669647,629.635437 
	C372.705505,644.464905 372.617096,659.295898 372.810333,674.123230 
	C372.896088,680.699402 376.215210,685.418396 382.318542,688.107666 
	C392.606049,692.640686 402.217651,689.589355 411.670959,685.409546 
	C412.572144,685.011047 413.610535,683.300781 413.448975,682.404236 
	C412.782471,678.706116 411.677826,675.086975 410.576263,670.828125 
	C408.289307,671.819275 406.680176,672.516663 404.367859,673.197449 
M258.814209,666.358582 
	C267.778107,651.828979 269.503754,635.981323 266.367340,619.639221 
	C261.813232,595.910522 245.733109,580.980469 221.643280,578.860229 
	C208.079376,577.666443 194.345718,578.486511 180.695099,578.140137 
	C176.636139,578.037170 175.223373,579.180725 175.240433,583.436523 
	C175.376511,617.422974 175.284348,651.410339 175.278687,685.397461 
	C175.278534,686.349609 175.463593,687.301697 175.570633,688.334290 
	C176.476257,688.523926 177.107056,688.773682 177.736633,688.770630 
	C191.893982,688.701782 206.085205,689.186279 220.199387,688.359619 
	C236.124588,687.426880 249.236984,680.448059 258.814209,666.358582 
M613.987976,618.908447 
	C616.319580,618.854614 618.653992,618.700562 620.982361,618.764160 
	C631.454590,619.050110 638.030457,625.560425 638.477173,636.006165 
	C638.703674,641.302612 638.033081,641.874817 632.865112,640.705261 
	C622.788757,638.425049 612.708496,638.008118 602.765808,641.206116 
	C593.120361,644.308533 585.877075,650.235840 583.795959,660.547363 
	C581.855469,670.161743 585.298279,678.331604 592.717285,684.621338 
	C601.978455,692.472656 621.317566,692.153076 632.123657,684.137390 
	C634.852661,682.113098 637.446838,679.907043 640.469604,677.491577 
	C641.178223,679.856812 641.551941,681.634827 642.235352,683.284607 
	C644.227661,688.094482 648.213440,689.934692 653.069580,689.547485 
	C655.479614,689.355347 658.563721,688.738525 659.983887,687.120728 
	C662.045593,684.772278 660.155518,675.251587 658.643616,673.005798 
	C657.357056,671.094727 656.247070,668.645569 656.183472,666.402588 
	C655.900085,656.410889 656.156616,646.405151 655.962097,636.409363 
	C655.689209,622.381653 649.212891,612.783020 636.098755,608.359375 
	C620.018494,602.935242 604.889771,606.434753 590.737366,615.046448 
	C589.745728,615.649841 588.653015,617.737366 588.991211,618.578064 
	C590.181396,621.536621 591.975708,624.252075 593.275024,626.582947 
	C600.441650,623.850281 606.779114,621.433838 613.987976,618.908447 
M558.866211,595.505676 
	C560.727966,595.002380 562.578613,594.120117 564.453491,594.065002 
	C570.775940,593.879211 577.107788,594.050049 583.434570,593.969971 
	C596.262451,593.807739 594.289429,596.014648 594.652710,582.384888 
	C594.742065,579.032898 593.350281,578.057068 590.198547,578.119629 
	C582.541504,578.271545 574.879639,578.187622 567.219727,578.186462 
	C547.737183,578.183472 528.254700,578.186096 508.772186,578.151001 
	C506.345551,578.146667 503.749603,577.987549 503.571289,581.257568 
	C503.348572,585.340637 503.517792,589.445129 503.517792,594.471008 
	C514.319214,594.471008 524.451233,594.652832 534.571289,594.385559 
	C538.897949,594.271423 540.308044,595.539856 540.272278,600.013672 
	C540.067322,625.656006 540.179260,651.300903 540.158142,676.944885 
	C540.148315,688.886414 540.126648,688.699768 552.187561,688.982239 
	C556.677734,689.087280 558.187500,687.927551 558.145996,683.198059 
	C557.913879,656.723572 558.009338,630.246033 558.034302,603.769592 
	C558.036621,601.286804 558.328430,598.804260 558.866211,595.505676 
M742.892334,682.377686 
	C744.140808,681.283020 745.528137,680.310730 746.616882,679.075317 
	C763.155090,660.309875 760.879883,629.622864 741.644531,613.643799 
	C727.880981,602.210205 706.775940,603.390686 694.718750,616.749451 
	C694.127258,617.404846 693.147522,617.709839 691.731995,618.539307 
	C691.404175,616.603455 690.789001,615.145569 690.996643,613.816101 
	C691.850525,608.349792 689.427002,606.502075 684.295288,607.204407 
	C683.313904,607.338684 682.286316,607.326172 681.300964,607.207336 
	C676.787292,606.663208 675.331970,608.437073 675.360291,613.105957 
	C675.553711,645.094116 675.355713,677.084473 675.490173,709.073181 
	C675.555115,724.525391 673.320007,721.348145 688.468079,721.810608 
	C692.032288,721.919373 693.019043,720.430847 692.975586,717.051514 
	C692.834106,706.057190 692.911743,695.059082 693.010132,684.063416 
	C693.027710,682.095093 693.545532,680.131226 693.888245,677.783020 
	C715.406128,693.334167 725.404480,694.281616 742.892334,682.377686 
M527.867249,511.843658 
	C521.578186,507.526276 515.156250,503.387146 509.044983,498.831299 
	C504.674042,495.572845 499.743317,495.976746 497.762848,500.481262 
	C495.803314,504.938049 499.340027,507.386871 501.911438,509.714722 
	C513.398010,520.113342 526.715942,526.946655 542.112244,529.486267 
	C568.650146,533.863831 591.926453,527.070862 611.797546,508.956543 
	C613.494934,507.409210 615.165955,504.982910 615.320374,502.854065 
	C615.450867,501.054321 613.806458,498.402496 612.164246,497.350281 
	C608.885803,495.249695 606.028137,497.059998 603.283936,499.472748 
	C581.009888,519.056824 556.024597,523.144836 527.867249,511.843658 
M467.860504,622.442627 
	C474.164490,627.286865 474.992798,633.810181 474.078949,641.574280 
	C470.566528,640.922302 467.646027,640.406006 464.736237,639.835327 
	C454.341553,637.796814 444.141571,638.235779 434.454468,642.808716 
	C424.980835,647.280945 419.159393,654.462097 419.289459,665.421326 
	C419.417419,676.200867 424.637573,684.141602 434.424500,688.042053 
	C446.923401,693.023315 458.944427,690.567200 469.965424,683.081848 
	C475.352570,679.422913 475.316620,679.370056 478.595490,684.713013 
	C481.242737,689.026733 492.640167,692.475830 494.781738,688.688354 
	C497.020660,684.728821 496.664886,678.158264 495.035156,673.587769 
	C494.070984,670.883728 492.275665,668.342285 492.273254,665.170776 
	C492.265991,655.511597 492.605621,645.836304 492.160431,636.197327 
	C491.612305,624.330200 487.234955,614.104065 475.481293,609.453003 
	C458.734192,602.826111 442.546814,605.200989 427.252655,614.461182 
	C424.813416,615.937988 424.251617,617.779175 425.678040,620.326538 
	C426.946442,622.591736 428.022797,624.964539 429.288788,627.499878 
	C432.921600,625.646484 435.953491,623.967773 439.092957,622.522766 
	C448.378876,618.248291 457.752136,616.558105 467.860504,622.442627 
M525.584656,480.714539 
	C522.258606,478.204926 518.836792,478.292023 516.305664,481.480713 
	C513.761536,484.685730 514.688477,487.949371 517.631348,490.773163 
	C539.236755,511.504089 572.931824,511.339996 594.559448,491.518402 
	C598.498962,487.907806 599.070312,483.857788 596.387329,481.073303 
	C593.591003,478.171112 589.998657,478.628265 585.729919,481.971039 
	C582.354309,484.614380 578.967224,487.526825 575.102417,489.213257 
	C557.172546,497.037170 540.757446,494.330200 525.584656,480.714539 
M333.517365,609.254456 
	C319.017395,603.589722 304.742004,604.906616 290.898529,611.239868 
	C281.319702,615.622009 280.927063,618.164734 287.141205,626.776123 
	C287.226990,626.895020 287.425964,626.932129 287.980042,627.217468 
	C290.353058,625.966736 292.949493,624.553040 295.585693,623.217834 
	C301.947113,619.995850 308.633240,618.267822 315.827820,618.861389 
	C327.462158,619.821289 334.712891,629.822510 331.612823,641.356750 
	C328.767822,640.848328 325.868286,640.347046 322.975372,639.810181 
	C310.973511,637.583069 299.463562,638.517761 288.682587,644.821899 
	C273.369843,653.775940 275.477112,673.913635 284.416992,682.797729 
	C294.554260,692.871826 311.894653,691.594238 320.878998,687.134033 
	C325.506561,684.836670 329.770966,681.807861 334.443756,678.960754 
	C335.475159,681.364685 336.157959,683.239929 337.060120,685.002869 
	C339.275146,689.331116 352.286682,692.141907 353.716858,688.258179 
	C355.300262,683.958435 354.121704,678.465149 353.467407,673.582520 
	C353.061279,670.552002 350.575745,667.726501 350.444550,664.746460 
	C350.027252,655.265991 350.533752,645.747070 350.201599,636.259949 
	C349.791107,624.536377 345.184326,615.056213 333.517365,609.254456 
M570.182800,463.738892 
	C565.423157,467.815277 559.929199,470.514832 553.710388,469.001495 
	C549.669067,468.018005 545.935669,465.524811 542.253540,463.382690 
	C538.988220,461.483063 535.815491,460.820374 533.356873,464.024384 
	C531.124817,466.933167 531.342712,470.204712 533.998474,473.093109 
	C544.575745,484.597290 566.975098,485.043701 578.019165,473.982269 
	C580.763794,471.233307 582.222595,468.136383 579.814026,464.658844 
	C577.453247,461.250214 574.204041,460.837280 570.182800,463.738892 
z"/>
					<path fill="#7FA42A" opacity="1.000000" stroke="none"
						d="
M258.613800,666.657104 
	C249.236984,680.448059 236.124588,687.426880 220.199387,688.359619 
	C206.085205,689.186279 191.893982,688.701782 177.736633,688.770630 
	C177.107056,688.773682 176.476257,688.523926 175.570633,688.334290 
	C175.463593,687.301697 175.278534,686.349609 175.278687,685.397461 
	C175.284348,651.410339 175.376511,617.422974 175.240433,583.436523 
	C175.223373,579.180725 176.636139,578.037170 180.695099,578.140137 
	C194.345718,578.486511 208.079376,577.666443 221.643280,578.860229 
	C245.733109,580.980469 261.813232,595.910522 266.367340,619.639221 
	C269.503754,635.981323 267.778107,651.828979 258.613800,666.657104 
M242.892624,661.037170 
	C243.968536,659.909912 245.458405,658.967834 246.057953,657.627502 
	C254.428421,638.915222 255.845123,620.825745 241.586136,603.859253 
	C236.535294,597.849365 230.527451,593.793152 222.828842,593.118408 
	C213.931290,592.338684 204.963364,592.333435 196.023132,592.102417 
	C194.759140,592.069702 193.479004,592.661926 192.206345,592.964966 
	C192.477493,593.313965 192.748642,593.662903 192.891953,594.675049 
	C192.920624,595.117676 192.949295,595.560303 192.815262,596.660950 
	C192.869232,597.106934 192.923203,597.552917 192.805374,598.495667 
	C192.854843,598.663696 192.904312,598.831787 192.767136,599.922241 
	C192.840408,623.280884 192.913696,646.639465 192.799423,670.773376 
	C193.865021,671.466003 194.930603,672.158569 196.788681,673.008057 
	C197.861603,672.966797 198.934540,672.925537 200.785629,673.108337 
	C201.854401,673.039246 202.923157,672.970154 204.918518,673.044250 
	C210.264648,672.990845 215.610764,672.937500 221.439072,673.458191 
	C224.615356,672.916443 228.105423,673.027832 230.897675,671.686584 
	C235.612106,669.422180 241.497406,667.936157 242.892624,661.037170 
z"/>
					<path fill="#50ADE8" opacity="1.000000" stroke="none"
						d="
M613.552246,618.962891 
	C606.779114,621.433838 600.441650,623.850281 593.275024,626.582947 
	C591.975708,624.252075 590.181396,621.536621 588.991211,618.578064 
	C588.653015,617.737366 589.745728,615.649841 590.737366,615.046448 
	C604.889771,606.434753 620.018494,602.935242 636.098755,608.359375 
	C649.212891,612.783020 655.689209,622.381653 655.962097,636.409363 
	C656.156616,646.405151 655.900085,656.410889 656.183472,666.402588 
	C656.247070,668.645569 657.357056,671.094727 658.643616,673.005798 
	C660.155518,675.251587 662.045593,684.772278 659.983887,687.120728 
	C658.563721,688.738525 655.479614,689.355347 653.069580,689.547485 
	C648.213440,689.934692 644.227661,688.094482 642.235352,683.284607 
	C641.551941,681.634827 641.178223,679.856812 640.469604,677.491577 
	C637.446838,679.907043 634.852661,682.113098 632.123657,684.137390 
	C621.317566,692.153076 601.978455,692.472656 592.717285,684.621338 
	C585.298279,678.331604 581.855469,670.161743 583.795959,660.547363 
	C585.877075,650.235840 593.120361,644.308533 602.765808,641.206116 
	C612.708496,638.008118 622.788757,638.425049 632.865112,640.705261 
	C638.033081,641.874817 638.703674,641.302612 638.477173,636.006165 
	C638.030457,625.560425 631.454590,619.050110 620.982361,618.764160 
	C618.653992,618.700562 616.319580,618.854614 613.552246,618.962891 
M622.860596,676.574219 
	C623.180298,676.485291 623.498291,676.389404 623.820007,676.308533 
	C632.704895,674.077332 641.021423,667.795715 638.879089,655.524048 
	C638.657043,654.251831 637.798828,652.424133 636.796204,652.058044 
	C626.867859,648.433105 616.684937,646.968933 606.914001,652.080505 
	C597.818298,656.838928 597.294128,668.037415 605.449890,674.189453 
	C610.515076,678.010315 616.214600,677.934143 622.860596,676.574219 
z"/>
					<path fill="#53ADEB" opacity="1.000000" stroke="none"
						d="
M558.676025,595.913635 
	C558.328430,598.804260 558.036621,601.286804 558.034302,603.769592 
	C558.009338,630.246033 557.913879,656.723572 558.145996,683.198059 
	C558.187500,687.927551 556.677734,689.087280 552.187561,688.982239 
	C540.126648,688.699768 540.148315,688.886414 540.158142,676.944885 
	C540.179260,651.300903 540.067322,625.656006 540.272278,600.013672 
	C540.308044,595.539856 538.897949,594.271423 534.571289,594.385559 
	C524.451233,594.652832 514.319214,594.471008 503.517792,594.471008 
	C503.517792,589.445129 503.348572,585.340637 503.571289,581.257568 
	C503.749603,577.987549 506.345551,578.146667 508.772186,578.151001 
	C528.254700,578.186096 547.737183,578.183472 567.219727,578.186462 
	C574.879639,578.187622 582.541504,578.271545 590.198547,578.119629 
	C593.350281,578.057068 594.742065,579.032898 594.652710,582.384888 
	C594.289429,596.014648 596.262451,593.807739 583.434570,593.969971 
	C577.107788,594.050049 570.775940,593.879211 564.453491,594.065002 
	C562.578613,594.120117 560.727966,595.002380 558.676025,595.913635 
z"/>
					<path fill="#7CA322" opacity="1.000000" stroke="none"
						d="
M403.081451,673.722229 
	C403.510254,673.547302 403.939056,673.372375 404.974457,673.445618 
	C409.769257,673.716309 409.897827,677.442444 410.590240,679.988831 
	C410.879944,681.054321 408.827789,683.620361 407.355225,684.146606 
	C401.955536,686.076294 396.859894,688.721375 390.589142,688.222046 
	C384.045746,687.701050 376.046387,680.214233 375.936493,674.054565 
	C375.741119,663.103333 375.270813,652.157227 375.017456,641.206665 
	C374.890350,635.714783 374.938171,630.217102 375.003326,624.723267 
	C375.038910,621.724609 374.262634,619.547241 370.884155,618.936462 
	C369.641724,618.711792 368.059418,618.157166 367.433472,617.218567 
	C366.321991,615.552124 365.308502,613.479980 365.300354,611.573059 
	C365.296753,610.720459 367.864166,609.076599 369.196991,609.143494 
	C373.367676,609.352966 375.312469,606.602783 375.698761,603.604858 
	C376.273590,599.143921 375.417938,594.517395 375.558105,589.973328 
	C375.621796,587.909119 376.203827,584.414307 377.265594,584.116455 
	C379.794556,583.406982 383.009552,583.607971 385.428772,584.647888 
	C386.766907,585.223022 387.398895,588.171326 387.736572,590.154175 
	C388.137573,592.508972 387.723602,594.990295 387.944672,597.390869 
	C388.284882,601.085388 387.591492,606.248413 389.686371,608.038269 
	C391.976624,609.995056 396.958221,608.662415 400.728149,609.098206 
	C402.151123,609.262695 404.286499,610.083313 404.635162,611.104187 
	C405.142181,612.588440 404.933380,615.389954 403.944427,616.084717 
	C402.002472,617.448914 399.062561,618.783630 396.981812,618.281433 
	C392.015930,617.083008 389.282410,619.095459 388.519012,623.364502 
	C387.564240,628.703430 387.266632,634.210144 387.223450,639.648682 
	C387.173584,645.935120 387.652588,652.229431 388.012695,658.514038 
	C388.224304,662.207214 388.388428,665.936584 389.057404,669.560364 
	C389.685272,672.961487 398.111176,677.832886 401.368958,676.761658 
	C402.184723,676.493469 402.523468,674.774475 403.081451,673.722229 
z"/>
					<path fill="#58AAE5" opacity="1.000000" stroke="none"
						d="
M742.624390,682.619690 
	C725.404480,694.281616 715.406128,693.334167 693.888245,677.783020 
	C693.545532,680.131226 693.027710,682.095093 693.010132,684.063416 
	C692.911743,695.059082 692.834106,706.057190 692.975586,717.051514 
	C693.019043,720.430847 692.032288,721.919373 688.468079,721.810608 
	C673.320007,721.348145 675.555115,724.525391 675.490173,709.073181 
	C675.355713,677.084473 675.553711,645.094116 675.360291,613.105957 
	C675.331970,608.437073 676.787292,606.663208 681.300964,607.207336 
	C682.286316,607.326172 683.313904,607.338684 684.295288,607.204407 
	C689.427002,606.502075 691.850525,608.349792 690.996643,613.816101 
	C690.789001,615.145569 691.404175,616.603455 691.731995,618.539307 
	C693.147522,617.709839 694.127258,617.404846 694.718750,616.749451 
	C706.775940,603.390686 727.880981,602.210205 741.644531,613.643799 
	C760.879883,629.622864 763.155090,660.309875 746.616882,679.075317 
	C745.528137,680.310730 744.140808,681.283020 742.624390,682.619690 
M754.031860,651.988708 
	C754.023682,649.657043 753.966797,647.324341 754.016724,644.993896 
	C754.149353,638.800964 754.097595,632.725464 749.419312,627.756897 
	C746.447510,624.600586 743.701294,621.224426 740.959167,617.861084 
	C736.053040,611.843506 729.701355,608.413940 722.016602,608.623779 
	C715.053345,608.813904 707.396423,607.786255 702.513245,614.919617 
	C701.979370,615.699524 700.970398,616.146362 700.207886,616.779175 
	C698.011780,618.601746 695.829224,620.440491 692.822632,622.958496 
	C689.434082,620.457642 684.957642,617.274475 685.737244,610.487915 
	C683.313171,610.487915 681.309387,610.487915 679.008423,610.487915 
	C679.008423,619.200134 678.793335,627.442322 679.173279,635.657043 
	C679.258667,637.502258 681.036743,640.100830 682.713562,640.865051 
	C686.045044,642.383362 686.845825,644.584045 687.053894,647.887207 
	C687.452209,654.211975 688.367554,660.503113 688.833862,666.825500 
	C688.931152,668.144714 688.407715,669.945007 687.496765,670.831299 
	C682.363403,675.825317 683.751709,681.707703 684.808044,687.611084 
	C685.560974,691.818848 686.681213,696.030151 686.751465,700.254578 
	C686.777283,701.804199 684.377014,703.530457 682.850952,704.921021 
	C681.859985,705.824036 679.426758,706.222778 679.397461,706.921204 
	C679.247070,710.507080 678.824585,714.503174 680.228210,717.561523 
	C680.972839,719.184082 685.207520,719.452148 687.926392,719.716125 
	C688.479858,719.769836 689.885193,717.075806 689.906128,715.634094 
	C690.072998,704.119202 689.969299,692.600769 690.038025,681.083923 
	C690.047729,679.458984 690.342041,677.771973 690.848938,676.226624 
	C692.072327,672.496948 694.058411,671.988159 697.037903,674.577576 
	C699.916016,677.078796 702.746887,679.637634 705.528748,682.245667 
	C714.318359,690.486023 728.184631,689.348267 736.274475,683.772339 
	C742.424988,679.533081 748.376587,674.367981 751.130676,667.064331 
	C752.817566,662.590820 753.174744,657.616028 754.031860,651.988708 
z"/>
					<path fill="#4FAFEC" opacity="1.000000" stroke="none"
						d="
M528.189636,512.040894 
	C556.024597,523.144836 581.009888,519.056824 603.283936,499.472748 
	C606.028137,497.059998 608.885803,495.249695 612.164246,497.350281 
	C613.806458,498.402496 615.450867,501.054321 615.320374,502.854065 
	C615.165955,504.982910 613.494934,507.409210 611.797546,508.956543 
	C591.926453,527.070862 568.650146,533.863831 542.112244,529.486267 
	C526.715942,526.946655 513.398010,520.113342 501.911438,509.714722 
	C499.340027,507.386871 495.803314,504.938049 497.762848,500.481262 
	C499.743317,495.976746 504.674042,495.572845 509.044983,498.831299 
	C515.156250,503.387146 521.578186,507.526276 528.189636,512.040894 
z"/>
					<path fill="#8AA44C" opacity="1.000000" stroke="none"
						d="
M467.549194,622.219055 
	C457.752136,616.558105 448.378876,618.248291 439.092957,622.522766 
	C435.953491,623.967773 432.921600,625.646484 429.288788,627.499878 
	C428.022797,624.964539 426.946442,622.591736 425.678040,620.326538 
	C424.251617,617.779175 424.813416,615.937988 427.252655,614.461182 
	C442.546814,605.200989 458.734192,602.826111 475.481293,609.453003 
	C487.234955,614.104065 491.612305,624.330200 492.160431,636.197327 
	C492.605621,645.836304 492.265991,655.511597 492.273254,665.170776 
	C492.275665,668.342285 494.070984,670.883728 495.035156,673.587769 
	C496.664886,678.158264 497.020660,684.728821 494.781738,688.688354 
	C492.640167,692.475830 481.242737,689.026733 478.595490,684.713013 
	C475.316620,679.370056 475.352570,679.422913 469.965424,683.081848 
	C458.944427,690.567200 446.923401,693.023315 434.424500,688.042053 
	C424.637573,684.141602 419.417419,676.200867 419.289459,665.421326 
	C419.159393,654.462097 424.980835,647.280945 434.454468,642.808716 
	C444.141571,638.235779 454.341553,637.796814 464.736237,639.835327 
	C467.646027,640.406006 470.566528,640.922302 474.078949,641.574280 
	C474.992798,633.810181 474.164490,627.286865 467.549194,622.219055 
M436.254211,619.654114 
	C438.616028,619.153442 441.020691,618.799805 443.331787,618.125305 
	C451.035675,615.876831 458.441010,614.412903 466.332245,618.412781 
	C474.083984,622.341980 475.797363,629.473877 477.813416,636.559265 
	C478.793396,640.003357 475.926544,643.038147 472.052490,642.916931 
	C466.596252,642.746155 461.150940,642.167786 455.706024,641.704407 
	C444.777069,640.774414 435.203735,642.903503 427.496033,651.743774 
	C421.819458,658.254456 419.860382,665.514893 423.210114,672.855652 
	C427.020752,681.206726 434.328674,686.597595 443.739075,687.976379 
	C454.018341,689.482300 462.724335,685.704163 470.459808,679.135864 
	C476.333313,674.148621 478.949158,674.973633 481.027679,682.284729 
	C481.664337,684.524109 488.032745,687.461548 490.652191,686.628479 
	C494.170532,685.509583 494.407166,683.086121 493.530640,679.877075 
	C491.592651,672.781677 488.950470,665.825623 489.940460,658.164917 
	C490.427582,654.395386 489.954102,650.509338 490.041290,646.678894 
	C490.246643,637.657776 489.229828,629.014648 484.576355,620.922791 
	C481.057404,614.803589 475.737885,611.069519 469.457916,609.293823 
	C464.467529,607.882812 458.947357,608.172607 453.656250,608.087524 
	C451.688293,608.055908 449.702942,609.104919 447.125671,609.829834 
	C445.382904,609.829834 442.803619,609.396790 440.459137,609.953125 
	C438.169006,610.496643 435.852325,611.730957 434.035278,613.246887 
	C432.093658,614.866760 430.255341,616.975098 429.299683,619.258484 
	C428.821198,620.401855 430.406464,622.408936 431.284882,624.587158 
	C433.235260,622.229736 434.402252,620.819153 436.254211,619.654114 
z"/>
					<path fill="#4FAFEC" opacity="1.000000" stroke="none"
						d="
M525.870117,480.954590 
	C540.757446,494.330200 557.172546,497.037170 575.102417,489.213257 
	C578.967224,487.526825 582.354309,484.614380 585.729919,481.971039 
	C589.998657,478.628265 593.591003,478.171112 596.387329,481.073303 
	C599.070312,483.857788 598.498962,487.907806 594.559448,491.518402 
	C572.931824,511.339996 539.236755,511.504089 517.631348,490.773163 
	C514.688477,487.949371 513.761536,484.685730 516.305664,481.480713 
	C518.836792,478.292023 522.258606,478.204926 525.870117,480.954590 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M333.871307,609.378540 
	C345.184326,615.056213 349.791107,624.536377 350.201599,636.259949 
	C350.533752,645.747070 350.027252,655.265991 350.444550,664.746460 
	C350.575745,667.726501 353.061279,670.552002 353.467407,673.582520 
	C354.121704,678.465149 355.300262,683.958435 353.716858,688.258179 
	C352.286682,692.141907 339.275146,689.331116 337.060120,685.002869 
	C336.157959,683.239929 335.475159,681.364685 334.443756,678.960754 
	C329.770966,681.807861 325.506561,684.836670 320.878998,687.134033 
	C311.894653,691.594238 294.554260,692.871826 284.416992,682.797729 
	C275.477112,673.913635 273.369843,653.775940 288.682587,644.821899 
	C299.463562,638.517761 310.973511,637.583069 322.975372,639.810181 
	C325.868286,640.347046 328.767822,640.848328 331.612823,641.356750 
	C334.712891,629.822510 327.462158,619.821289 315.827820,618.861389 
	C308.633240,618.267822 301.947113,619.995850 295.585693,623.217834 
	C292.949493,624.553040 290.353058,625.966736 287.980042,627.217468 
	C287.425964,626.932129 287.226990,626.895020 287.141205,626.776123 
	C280.927063,618.164734 281.319702,615.622009 290.898529,611.239868 
	C304.742004,604.906616 319.017395,603.589722 333.871307,609.378540 
M294.977692,686.621277 
	C301.051300,690.879150 307.757172,689.267517 313.574524,687.094971 
	C319.890228,684.736328 325.422577,680.296692 331.331360,676.823730 
	C333.637817,675.468079 335.541443,675.395020 336.938232,678.351868 
	C338.116455,680.845886 339.636719,683.253723 341.389801,685.383362 
	C344.093536,688.667725 347.472229,689.318665 350.954681,686.513672 
	C353.459503,684.496094 354.333008,676.622986 351.823578,674.595398 
	C347.969788,671.481750 348.082855,667.562744 347.988159,663.380249 
	C347.736389,652.261658 347.656982,641.125183 346.909637,630.036194 
	C346.269348,620.535461 335.101135,610.227661 327.519714,609.171387 
	C315.160553,607.449524 302.929443,606.360107 291.526733,613.996338 
	C286.019745,617.684265 285.251282,618.592651 290.130646,623.637512 
	C300.407593,617.113647 302.552338,615.533936 314.992432,615.982666 
	C328.133972,616.456665 336.049988,626.770508 334.743195,638.674316 
	C334.549469,640.438782 331.608490,642.866760 329.620789,643.225037 
	C327.132782,643.673340 324.339691,642.143311 321.637604,641.739685 
	C311.335266,640.200745 301.240509,640.066528 291.998779,646.092529 
	C286.668823,649.567993 281.859253,654.196960 280.286102,659.929199 
	C277.185425,671.227600 283.321991,681.453125 294.977692,686.621277 
z"/>
					<path fill="#8AA44C" opacity="1.000000" stroke="none"
						d="
M402.644348,673.617432 
	C402.523468,674.774475 402.184723,676.493469 401.368958,676.761658 
	C398.111176,677.832886 389.685272,672.961487 389.057404,669.560364 
	C388.388428,665.936584 388.224304,662.207214 388.012695,658.514038 
	C387.652588,652.229431 387.173584,645.935120 387.223450,639.648682 
	C387.266632,634.210144 387.564240,628.703430 388.519012,623.364502 
	C389.282410,619.095459 392.015930,617.083008 396.981812,618.281433 
	C399.062561,618.783630 402.002472,617.448914 403.944427,616.084717 
	C404.933380,615.389954 405.142181,612.588440 404.635162,611.104187 
	C404.286499,610.083313 402.151123,609.262695 400.728149,609.098206 
	C396.958221,608.662415 391.976624,609.995056 389.686371,608.038269 
	C387.591492,606.248413 388.284882,601.085388 387.944672,597.390869 
	C387.723602,594.990295 388.137573,592.508972 387.736572,590.154175 
	C387.398895,588.171326 386.766907,585.223022 385.428772,584.647888 
	C383.009552,583.607971 379.794556,583.406982 377.265594,584.116455 
	C376.203827,584.414307 375.621796,587.909119 375.558105,589.973328 
	C375.417938,594.517395 376.273590,599.143921 375.698761,603.604858 
	C375.312469,606.602783 373.367676,609.352966 369.196991,609.143494 
	C367.864166,609.076599 365.296753,610.720459 365.300354,611.573059 
	C365.308502,613.479980 366.321991,615.552124 367.433472,617.218567 
	C368.059418,618.157166 369.641724,618.711792 370.884155,618.936462 
	C374.262634,619.547241 375.038910,621.724609 375.003326,624.723267 
	C374.938171,630.217102 374.890350,635.714783 375.017456,641.206665 
	C375.270813,652.157227 375.741119,663.103333 375.936493,674.054565 
	C376.046387,680.214233 384.045746,687.701050 390.589142,688.222046 
	C396.859894,688.721375 401.955536,686.076294 407.355225,684.146606 
	C408.827789,683.620361 410.879944,681.054321 410.590240,679.988831 
	C409.897827,677.442444 409.769257,673.716309 405.326050,673.453979 
	C406.680176,672.516663 408.289307,671.819275 410.576263,670.828125 
	C411.677826,675.086975 412.782471,678.706116 413.448975,682.404236 
	C413.610535,683.300781 412.572144,685.011047 411.670959,685.409546 
	C402.217651,689.589355 392.606049,692.640686 382.318542,688.107666 
	C376.215210,685.418396 372.896088,680.699402 372.810333,674.123230 
	C372.617096,659.295898 372.705505,644.464905 372.669647,629.635437 
	C372.650177,621.578369 372.641052,621.583618 364.767120,621.267334 
	C364.120056,621.241272 363.478485,621.078979 362.199524,620.882446 
	C362.199524,617.082642 361.791656,613.250183 362.436615,609.603882 
	C362.642395,608.440491 365.666962,606.977173 367.358002,607.030701 
	C371.724701,607.169189 372.787201,605.202637 372.633118,601.316345 
	C372.415344,595.825989 372.617004,590.320190 372.498077,584.824036 
	C372.432800,581.808716 373.232330,580.125366 376.722778,580.321350 
	C381.002930,580.561707 385.306854,580.378662 390.331024,580.378662 
	C390.331024,587.748779 390.599396,594.539001 390.240326,601.295898 
	C389.984406,606.111267 392.066986,607.612366 396.407684,607.257385 
	C397.400787,607.176208 398.407898,607.283325 399.405518,607.237427 
	C408.012421,606.841248 407.768097,606.842896 407.980804,615.209473 
	C408.102722,620.005188 406.521606,621.536987 401.686981,621.438354 
	C391.039948,621.221130 390.007111,618.736755 390.253021,633.119080 
	C390.421051,642.946899 390.250885,652.780090 390.307129,662.610413 
	C390.358948,671.664795 392.998749,674.040100 402.644348,673.617432 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M393.901306,234.986206 
	C394.505920,234.153030 395.110596,233.319839 396.139099,232.136505 
	C397.381989,231.215561 398.200989,230.644775 399.020020,230.073990 
	C399.562286,229.892990 400.104584,229.711990 401.240601,229.316376 
	C413.167572,225.688049 413.167511,225.688049 413.238403,213.798386 
	C413.240387,213.465637 413.207214,213.127579 413.253448,212.800934 
	C413.825958,208.756180 412.077087,206.843140 408.000214,207.052322 
	C407.573730,206.947159 407.147217,206.842010 406.111023,206.497284 
	C400.492371,204.546646 395.522858,202.704773 390.457855,201.180069 
	C387.950806,200.425369 385.274170,199.936523 382.663361,199.885391 
	C377.079651,199.776062 371.325989,200.922729 365.936523,199.939819 
	C356.214600,198.166779 353.726196,192.755203 355.438904,180.187622 
	C355.438904,180.187622 355.587738,179.943253 355.837891,179.641464 
	C362.156219,171.257812 369.272644,169.235977 377.017334,173.704681 
	C374.892273,174.023117 372.939056,173.981598 370.838562,173.725159 
	C370.127289,173.654800 369.563232,173.799362 368.802612,174.148743 
	C362.181152,174.292038 358.095978,179.968002 358.228851,185.625763 
	C358.308044,188.996674 360.046936,193.669952 362.617859,195.367279 
	C368.314728,199.128326 375.086395,198.759186 381.701721,196.691666 
	C383.620575,196.091949 386.037140,196.027145 387.941162,196.624161 
	C395.444305,198.976807 402.782074,201.857498 410.287872,204.200638 
	C414.110291,205.393906 416.328461,207.055954 416.051025,211.503860 
	C415.723999,216.746490 415.974335,222.025116 415.974335,228.374634 
	C413.136566,229.107697 409.512970,230.006622 405.912201,230.989243 
	C404.053833,231.496384 402.108612,231.916397 400.441193,232.817307 
	C399.156403,233.511505 398.243683,234.894318 397.165527,235.970947 
	C398.464111,237.165802 399.713959,238.421539 401.088135,239.522018 
	C401.559418,239.899429 402.333160,239.977127 402.983521,240.040939 
	C409.973816,240.726959 416.958466,241.785522 423.961975,241.936295 
	C431.312042,242.094528 438.725769,241.737274 446.016907,240.837418 
	C448.490875,240.532074 450.676270,237.888550 452.993561,236.313538 
	C451.085999,234.531891 449.370575,232.446182 447.202789,231.076324 
	C445.776764,230.175201 443.561340,230.613998 441.947876,229.895111 
	C440.041473,229.045746 437.272522,227.894653 436.863434,226.348755 
	C435.709747,221.989777 435.240692,217.370682 435.117340,212.837357 
	C434.951935,206.760910 437.148682,204.353500 443.071167,202.840210 
	C443.867584,202.636703 444.590912,202.163315 445.372009,201.881104 
	C455.146515,198.349625 464.834442,194.404709 475.620697,196.008957 
	C477.086334,196.226929 478.624542,196.147995 480.110168,196.006683 
	C485.848907,195.460861 491.360565,189.997772 489.746979,185.982025 
	C488.444885,182.741516 485.957397,179.977325 483.995789,176.660446 
	C483.994598,175.863754 483.993439,175.408463 484.250854,174.867752 
	C486.527222,176.297256 488.544952,177.812180 490.562714,179.327087 
	C490.562714,179.327087 490.632050,179.358536 490.673950,179.750504 
	C495.200226,189.961609 492.485413,196.393433 481.967926,198.781860 
	C476.725830,199.972290 471.095093,199.425873 465.644043,199.734131 
	C463.547455,199.852692 461.398315,199.917969 459.384613,200.438599 
	C455.532288,201.434525 451.809235,202.944839 447.944275,203.874832 
	C438.626282,206.116913 438.558960,206.082352 437.408905,215.340408 
	C436.309174,224.193573 437.791290,225.697296 446.318024,228.267990 
	C450.517822,229.534164 454.145721,232.697296 458.032410,235.002029 
	C457.992004,235.441925 457.951569,235.881805 457.661682,236.865387 
	C453.879700,239.358414 450.560211,242.078598 446.777863,243.123688 
	C430.361694,247.659515 414.032867,246.893311 397.998169,241.001617 
	C397.532501,240.683868 397.066803,240.366104 396.205383,239.669128 
	C395.173523,237.855331 394.537415,236.420761 393.901306,234.986206 
z"/>
					<path fill="#80A230" opacity="1.000000" stroke="none"
						d="
M394.064545,251.554077 
	C396.073547,251.619812 398.082581,251.685562 400.966217,251.779602 
	C413.097412,251.841507 424.354736,251.807053 435.610291,251.931335 
	C443.865387,252.022446 452.118134,252.321808 460.371948,252.528366 
	C460.371948,252.528366 460.486572,252.523407 460.554932,252.864166 
	C461.335480,253.918503 462.047638,254.632065 462.759827,255.345612 
	C462.759827,255.345612 462.744171,255.707428 462.798676,256.252289 
	C462.556030,262.991730 460.445953,265.153839 454.332520,265.169067 
	C435.729919,265.215424 417.126495,265.104858 398.524963,265.253876 
	C393.963959,265.290375 389.659607,265.113922 386.029877,261.966217 
	C386.307648,259.311737 386.585449,256.657257 387.319946,253.643173 
	C388.270508,252.967407 388.764343,252.651230 389.258179,252.335068 
	C389.258179,252.335068 389.311890,252.287598 389.716370,252.296204 
	C391.435394,252.054581 392.749969,251.804337 394.064545,251.554077 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M460.929382,372.965363 
	C460.602692,373.019958 460.341583,373.180908 459.703491,373.746887 
	C450.349274,380.875000 440.272919,383.346130 429.207794,382.002625 
	C421.236023,381.034698 414.187988,377.976532 408.172333,372.585114 
	C403.276062,368.196991 401.716644,362.440155 401.897369,356.234406 
	C391.465729,356.234406 381.675598,356.122498 371.893524,356.352325 
	C370.386993,356.387756 368.136169,357.649872 367.569092,358.935486 
	C365.392700,363.869659 361.535492,364.441559 356.928864,364.152435 
	C353.953217,363.965698 350.951111,364.199860 347.960876,364.245422 
	C347.960876,364.245392 347.558868,364.007355 347.148682,363.635742 
	C346.189484,361.252655 345.185455,359.245911 345.164764,357.229034 
	C345.023102,343.423950 344.993896,329.615936 345.111786,315.810425 
	C345.233185,301.599518 342.353424,301.237488 359.782166,302.313721 
	C358.689667,303.348145 357.805481,304.135071 356.526672,304.927979 
	C348.523193,303.875732 346.877441,305.622192 348.065186,313.520355 
	C348.310364,315.150513 348.869720,316.777832 348.839142,318.398041 
	C348.603821,330.857422 348.157257,343.314026 348.070679,355.773529 
	C348.059326,357.408630 349.308136,359.733765 350.682770,360.562683 
	C354.078217,362.610077 361.319763,360.364716 364.523743,357.448669 
	C366.269501,355.859802 368.764374,354.363647 371.022797,354.196655 
	C380.121155,353.523712 389.257904,353.177551 398.382660,353.130096 
	C403.496063,353.103485 406.130707,356.931305 406.191772,362.287079 
	C406.213837,364.223480 407.157257,366.856598 408.603027,367.937500 
	C413.004272,371.228027 417.908691,373.835449 422.519745,376.857544 
	C430.703583,382.221222 439.695465,380.075470 447.359650,377.337952 
	C459.672180,372.940063 467.329041,362.548431 470.708466,350.140564 
	C472.569183,343.308990 476.465363,340.607819 482.364227,340.112335 
	C491.387909,339.354309 500.488098,339.031189 509.530426,339.319092 
	C511.677887,339.387482 513.724915,342.611328 515.413086,344.349823 
	C510.998840,343.580719 506.989716,342.845520 502.980560,342.110321 
	C501.907227,341.969757 500.833893,341.829224 498.900757,341.660767 
	C491.479248,342.442078 484.878967,343.035950 478.381134,344.201202 
	C476.600098,344.520630 474.405243,346.214539 473.636627,347.856445 
	C470.426270,354.714508 467.686676,361.792908 464.774475,368.790527 
	C464.774475,368.790527 464.906158,368.790161 464.548645,368.919617 
	C463.103882,370.354523 462.016632,371.659943 460.929382,372.965363 
z"/>
					<path fill="#8AA44C" opacity="1.000000" stroke="none"
						d="
M482.065033,304.700623 
	C489.383148,304.747498 496.701263,304.794373 504.855560,304.923340 
	C506.477417,304.943512 507.263123,304.881592 508.048798,304.819641 
	C509.419739,304.880219 510.790680,304.940796 512.822754,305.066650 
	C513.658508,305.093750 513.833069,305.055603 514.007690,305.017426 
	C514.442749,305.064117 514.877808,305.110779 515.987671,305.332214 
	C532.624390,306.115601 548.032166,308.541809 560.984680,319.020691 
	C561.464355,319.610687 561.944092,320.200684 562.786377,321.187347 
	C563.847595,322.203888 564.546326,322.823792 565.244995,323.443665 
	C565.244995,323.443665 565.347534,323.624603 565.452148,324.012512 
	C568.735168,331.985443 572.780457,339.335388 574.914490,347.203674 
	C579.591919,364.449646 579.330872,382.249390 578.964661,399.978546 
	C578.964661,399.978546 578.501221,400.164154 577.859802,400.199768 
	C563.481873,400.358551 549.745300,400.481720 535.949219,400.282043 
	C535.212097,398.620605 534.534668,397.281982 533.861694,395.568878 
	C534.029907,394.466278 534.193604,393.738129 534.630371,392.972595 
	C535.569153,392.634521 536.234863,392.333832 537.200439,392.281128 
	C538.270447,394.445557 537.530884,397.199005 541.292236,397.114288 
	C551.768433,396.878418 562.257751,397.174652 572.729736,396.859009 
	C574.177795,396.815308 576.774658,394.501343 576.776001,393.236176 
	C576.786865,383.129333 576.302246,373.021881 575.957214,362.915039 
	C575.946289,362.594025 575.909058,362.153748 575.704529,361.977203 
	C571.656616,358.483429 573.008179,353.449951 572.081787,349.094574 
	C571.329407,345.557861 571.853577,341.535431 570.368164,338.415710 
	C567.694214,332.800049 563.951904,327.691040 560.615540,322.395905 
	C560.279419,321.862427 559.699402,321.458344 559.175903,321.070740 
	C547.910950,312.730591 535.266174,308.789307 521.252563,308.936615 
	C509.441437,309.060791 497.627167,309.025940 485.815704,308.913727 
	C481.533295,308.873047 477.220032,307.928101 472.982666,308.238739 
	C466.029510,308.748444 459.614349,307.095642 453.686462,304.737518 
	C457.113861,304.683197 460.100281,304.648285 463.086731,304.613403 
	C463.796631,304.662354 464.506561,304.711304 466.028564,304.808868 
	C471.915466,304.805176 476.990265,304.752930 482.065033,304.700623 
z"/>
					<path fill="#53ADEB" opacity="1.000000" stroke="none"
						d="
M570.457336,463.504333 
	C574.204041,460.837280 577.453247,461.250214 579.814026,464.658844 
	C582.222595,468.136383 580.763794,471.233307 578.019165,473.982269 
	C566.975098,485.043701 544.575745,484.597290 533.998474,473.093109 
	C531.342712,470.204712 531.124817,466.933167 533.356873,464.024384 
	C535.815491,460.820374 538.988220,461.483063 542.253540,463.382690 
	C545.935669,465.524811 549.669067,468.018005 553.710388,469.001495 
	C559.929199,470.514832 565.423157,467.815277 570.457336,463.504333 
z"/>
					<path fill="#EDF5D7" opacity="1.000000" stroke="none"
						d="
M463.054047,304.261047 
	C460.100281,304.648285 457.113861,304.683197 453.691833,304.753418 
	C453.256256,304.788757 453.241516,304.757202 453.171021,304.404541 
	C452.870331,303.367462 452.640106,302.683075 452.525757,301.545441 
	C452.722137,295.269135 452.844696,289.446320 452.874725,283.623016 
	C452.941315,270.711395 452.922363,270.711304 439.535889,272.394958 
	C438.084290,272.418457 437.026062,272.535095 435.654297,272.535431 
	C434.898651,272.450470 434.456604,272.481842 433.757690,272.418030 
	C433.325165,272.372437 433.149445,272.421997 432.555054,272.392883 
	C430.434326,272.351105 428.732269,272.388031 426.765411,272.337250 
	C426.321930,272.300812 426.143219,272.352081 425.522064,272.327759 
	C422.385651,272.289398 419.691681,272.326599 416.544312,272.238708 
	C410.067169,272.211548 404.043365,272.309418 397.704926,272.354248 
	C396.973175,272.363159 396.556091,272.425140 395.926147,272.359436 
	C395.085358,272.118469 394.457397,272.005188 393.829468,271.891876 
	C392.041473,270.409882 390.051147,269.106567 388.531982,267.387085 
	C387.396912,266.102325 386.869873,264.280396 386.050964,262.331940 
	C389.659607,265.113922 393.963959,265.290375 398.524963,265.253876 
	C417.126495,265.104858 435.729919,265.215424 454.332520,265.169067 
	C460.445953,265.153839 462.556030,262.991730 462.897552,256.403992 
	C465.774353,258.234802 465.440430,261.267975 463.765991,263.532074 
	C462.516937,265.221008 459.978424,266.387085 457.812195,266.859253 
	C455.029602,267.465698 452.044769,267.144257 449.147095,267.222534 
	C449.124542,267.930573 449.102020,268.638611 449.079468,269.346680 
	C451.491730,269.720032 453.903992,270.093414 456.218048,270.451569 
	C455.799255,279.386780 455.351807,288.213013 454.989838,297.042755 
	C454.860443,300.198364 455.137726,302.924286 459.710999,301.433289 
	C460.465240,301.187378 461.900696,303.030853 463.054047,304.261047 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M536.008789,400.604889 
	C549.745300,400.481720 563.481873,400.358551 577.651001,400.336456 
	C578.369812,401.772278 578.656067,403.106995 578.487366,404.833801 
	C564.723206,405.241089 551.413940,405.251953 538.104736,405.273682 
	C532.328613,405.283112 530.781494,407.508545 532.681763,413.001434 
	C534.061768,416.990540 537.321106,418.039948 540.996033,418.030731 
	C552.634644,418.001526 564.272949,417.826508 575.911377,417.707855 
	C574.009888,418.799835 572.227600,420.330658 570.180603,420.886627 
	C566.621887,421.853210 562.899780,422.217682 558.686279,422.683655 
	C557.476624,422.169373 556.826843,421.806000 556.177002,421.442657 
	C551.569031,418.912476 548.266907,420.509766 545.739746,427.118439 
	C545.226379,424.824493 544.859619,423.185425 544.657410,422.282074 
	C541.024719,420.887573 537.785400,419.935730 534.818481,418.442474 
	C529.208740,415.619080 527.575378,409.388611 530.303894,403.682190 
	C531.107178,402.002197 530.889465,399.829041 531.115295,397.879059 
	C531.419006,395.257019 532.074768,392.610199 531.921936,390.012665 
	C531.369263,380.619904 530.555115,371.242188 529.785767,361.863220 
	C529.735168,361.246765 529.387085,360.472656 528.917114,360.099884 
	C521.664978,354.347015 517.063721,344.623962 505.853394,344.708954 
	C504.946411,344.715820 504.029968,343.474487 503.049255,342.461731 
	C506.989716,342.845520 510.998840,343.580719 515.623413,344.548462 
	C516.777649,344.987915 517.316345,345.194855 517.983521,345.607574 
	C518.562500,345.977783 519.013000,346.142273 519.628418,346.584961 
	C520.539062,347.231354 521.284790,347.599518 522.144897,348.167175 
	C522.467712,348.624786 522.737793,348.749054 523.177917,349.022278 
	C524.082092,349.923737 524.877869,350.542297 525.786926,351.472778 
	C526.872314,352.659271 527.844421,353.533844 528.841431,354.560455 
	C528.866394,354.712494 529.075867,354.938568 529.069458,355.251923 
	C529.616699,356.407806 530.170349,357.250305 530.799866,358.436890 
	C531.615234,360.194672 532.354858,361.608337 533.106995,363.302063 
	C533.351685,364.080536 533.583923,364.578888 533.788452,365.452606 
	C533.942810,366.554626 534.124878,367.281342 534.292480,368.419678 
	C534.304504,376.890900 534.330872,384.950439 534.357300,393.010010 
	C534.193604,393.738129 534.029907,394.466278 533.643738,395.860779 
	C533.118469,398.625854 531.846375,401.339569 536.008789,400.604889 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M460.565704,252.099945 
	C452.118134,252.321808 443.865387,252.022446 435.610291,251.931335 
	C424.354736,251.807053 413.097412,251.841507 401.384399,251.670135 
	C402.050293,250.696350 403.075409,249.388275 404.317291,249.132507 
	C406.287476,248.726746 408.402496,249.024307 410.456207,249.024307 
	C410.512604,248.482025 410.569000,247.939743 410.625397,247.397461 
	C407.592285,246.703003 404.485870,245.233459 401.544739,245.511642 
	C397.925018,245.854050 398.142456,243.973831 397.997406,241.383759 
	C414.032867,246.893311 430.361694,247.659515 446.777863,243.123688 
	C450.560211,242.078598 453.879700,239.358414 457.674316,237.196838 
	C458.496094,238.114868 459.055786,239.245132 459.653717,240.452637 
	C453.471344,243.101059 447.669525,245.586472 440.894409,248.488815 
	C443.602722,248.698700 445.653748,248.950104 447.709320,248.995926 
	C451.040375,249.070175 454.396637,248.792038 457.694733,249.128403 
	C458.791351,249.240250 459.742065,250.782974 460.565704,252.099945 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M348.017944,364.591187 
	C350.951111,364.199860 353.953217,363.965698 356.928864,364.152435 
	C361.535492,364.441559 365.392700,363.869659 367.569092,358.935486 
	C368.136169,357.649872 370.386993,356.387756 371.893524,356.352325 
	C381.675598,356.122498 391.465729,356.234406 401.897369,356.234406 
	C401.716644,362.440155 403.276062,368.196991 408.172333,372.585114 
	C414.187988,377.976532 421.236023,381.034698 429.207794,382.002625 
	C440.272919,383.346130 450.349274,380.875000 459.597290,373.956299 
	C457.870331,376.186523 456.226685,379.247070 453.669861,380.694855 
	C443.579529,386.408417 432.445251,384.810516 421.696259,383.659058 
	C417.888702,383.251160 414.405853,379.806030 410.778687,377.740601 
	C410.639679,377.661407 410.562164,377.450775 410.421143,377.401794 
	C401.936066,374.455505 400.199860,367.329254 399.732117,359.015900 
	C390.229431,359.015900 381.047241,358.759430 371.904999,359.248749 
	C370.229156,359.338440 368.257751,362.227966 367.240692,364.238831 
	C365.910828,366.868195 364.720001,367.998108 361.535492,367.195435 
	C359.239563,366.616760 356.517578,366.911163 354.176636,367.534027 
	C351.204712,368.324738 349.347382,367.600403 348.017944,364.591187 
z"/>
					<path fill="#69B1DD" opacity="1.000000" stroke="none"
						d="
M556.018372,421.725708 
	C556.826843,421.806000 557.476624,422.169373 558.268433,422.678406 
	C561.286682,428.575836 564.127075,434.345917 567.050110,440.073853 
	C569.703064,445.272644 569.122498,450.467194 566.346436,455.199707 
	C563.811646,459.520813 559.307251,460.365417 554.565247,459.897308 
	C549.836304,459.430573 546.052612,455.288269 545.084167,450.510376 
	C543.696045,443.661865 547.735474,438.587280 550.220459,433.010712 
	C551.895325,429.252106 553.966064,425.669861 556.018372,421.725708 
M548.144104,444.988525 
	C548.730225,447.295929 549.161804,449.656555 549.934082,451.899841 
	C551.628174,456.821014 554.932251,458.447937 559.322998,456.721069 
	C563.009460,455.271179 566.754944,448.231323 565.468384,444.991486 
	C563.889404,441.015167 562.456543,436.942383 560.425903,433.201447 
	C559.522400,431.536926 557.311340,430.582123 555.693726,429.305206 
	C554.772461,430.909973 553.736328,432.460968 552.954102,434.130859 
	C551.387817,437.474670 549.960449,440.883545 548.144104,444.988525 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M377.189178,173.344711 
	C369.272644,169.235977 362.156219,171.257812 355.794037,179.505280 
	C354.319092,175.327606 359.251556,169.542282 364.354340,169.340546 
	C364.991821,169.315338 365.947174,169.930832 366.239655,169.670425 
	C372.156677,164.402084 376.478485,172.139252 381.789795,171.534409 
	C381.912109,171.520477 382.105103,171.685120 382.186371,171.815536 
	C386.591919,178.887787 390.718933,173.692337 395.049835,171.635162 
	C400.931458,168.841400 406.643951,165.580429 413.730865,166.787262 
	C415.898407,167.156387 418.352417,165.278107 420.728302,165.086395 
	C431.331726,164.230759 441.263763,167.333679 450.960907,171.050018 
	C452.975983,171.822281 454.299133,174.400024 455.953003,176.589539 
	C455.964813,177.033417 455.830444,177.237747 455.481079,177.180527 
	C450.080383,174.731888 445.239105,171.609634 439.938324,170.086182 
	C424.080353,165.528580 408.789398,168.116745 394.083710,175.148026 
	C390.919312,176.661011 387.901520,179.304245 384.029785,176.965942 
	C384.033813,177.000610 384.070190,176.941025 383.893677,176.657043 
	C382.132782,175.580505 380.548370,174.787949 378.690002,173.828979 
	C378.007080,173.556610 377.598114,173.450668 377.189178,173.344711 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M458.368073,235.000397 
	C454.145721,232.697296 450.517822,229.534164 446.318024,228.267990 
	C437.791290,225.697296 436.309174,224.193573 437.408905,215.340408 
	C438.558960,206.082352 438.626282,206.116913 447.944275,203.874832 
	C451.809235,202.944839 455.532288,201.434525 459.384613,200.438599 
	C461.398315,199.917969 463.547455,199.852692 465.644043,199.734131 
	C471.095093,199.425873 476.725830,199.972290 481.967926,198.781860 
	C492.485413,196.393433 495.200226,189.961609 490.663300,179.787048 
	C494.111664,180.140106 495.895386,181.775757 495.274200,185.822220 
	C493.438995,197.775986 490.999695,200.518997 478.949036,201.968872 
	C472.477722,202.747467 465.834778,202.356888 459.446259,203.490860 
	C453.538513,204.539490 447.866699,206.917496 440.902771,209.078842 
	C440.648376,212.401810 440.051300,216.602158 440.093506,220.796051 
	C440.126892,224.113159 444.905304,226.825928 449.184845,226.425476 
	C452.547150,226.110855 459.551147,230.640762 460.394470,233.814056 
	C460.455322,234.043030 459.295837,234.596298 458.368073,235.000397 
z"/>
					<path fill="#91AA55" opacity="1.000000" stroke="none"
						d="
M576.115173,417.574768 
	C564.272949,417.826508 552.634644,418.001526 540.996033,418.030731 
	C537.321106,418.039948 534.061768,416.990540 532.681763,413.001434 
	C530.781494,407.508545 532.328613,405.283112 538.104736,405.273682 
	C551.413940,405.251953 564.723206,405.241089 578.495789,405.184814 
	C579.152466,405.354034 579.353394,405.556763 579.723267,406.328186 
	C579.133057,410.202332 578.381470,413.500336 577.629822,416.798309 
	C577.192871,417.012756 576.755920,417.227203 576.115173,417.574768 
M550.507568,407.962311 
	C547.176941,407.963684 543.803894,407.649323 540.531921,408.087952 
	C538.646545,408.340759 535.353516,409.932495 535.413757,410.711212 
	C535.621338,413.396027 536.580139,416.147491 540.515869,416.088257 
	C551.171448,415.927979 561.831482,415.987488 572.488892,416.069519 
	C575.571411,416.093231 577.132568,415.071930 577.059082,411.741852 
	C576.987610,408.502106 575.166565,407.922241 572.488159,407.944000 
	C565.494263,408.000763 558.499634,407.962494 550.507568,407.962311 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M579.299011,400.121887 
	C579.330872,382.249390 579.591919,364.449646 574.914490,347.203674 
	C572.780457,339.335388 568.735168,331.985443 565.512695,324.097046 
	C567.210083,324.823761 569.353394,325.506104 570.608582,326.956879 
	C573.156677,329.901886 575.860779,332.359070 575.371094,337.497925 
	C574.881531,342.634979 577.244141,348.065582 578.487488,353.336578 
	C579.078552,355.842316 580.517944,358.249695 580.640015,360.747223 
	C581.253784,373.307037 581.591858,385.881134 581.871704,398.453705 
	C581.884766,399.040649 580.413330,399.660645 579.299011,400.121887 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M359.990448,302.066254 
	C342.353424,301.237488 345.233185,301.599518 345.111786,315.810425 
	C344.993896,329.615936 345.023102,343.423950 345.164764,357.229034 
	C345.185455,359.245911 346.189484,361.252655 346.917145,363.604858 
	C344.017548,364.277222 342.989319,362.712921 342.995972,359.830414 
	C343.037903,341.594849 343.019745,323.359100 343.007843,305.123413 
	C343.005768,301.952484 344.372681,300.052826 347.701569,300.027740 
	C352.718048,299.989899 357.780945,299.628357 362.730927,300.204803 
	C364.945709,300.462708 366.934357,302.662476 368.716614,304.467224 
	C367.684479,304.929962 366.961395,304.906494 366.101318,304.561432 
	C364.630890,303.536133 363.297455,302.832397 361.639709,302.050751 
	C360.873749,302.003967 360.432098,302.035095 359.990448,302.066254 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M355.028015,180.171570 
	C353.726196,192.755203 356.214600,198.166779 365.936523,199.939819 
	C371.325989,200.922729 377.079651,199.776062 382.663361,199.885391 
	C385.274170,199.936523 387.950806,200.425369 390.457855,201.180069 
	C395.522858,202.704773 400.492371,204.546646 405.780853,206.490479 
	C404.907593,207.253311 403.560852,208.463806 402.634308,208.199768 
	C397.710022,206.796509 392.940765,204.848160 388.014343,203.454224 
	C385.634247,202.780792 382.588776,201.942322 380.600433,202.844452 
	C372.778168,206.393463 365.775177,203.176071 358.890472,200.624466 
	C352.472809,198.245956 350.231415,185.860855 355.028015,180.171570 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M465.130615,368.817261 
	C467.686676,361.792908 470.426270,354.714508 473.636627,347.856445 
	C474.405243,346.214539 476.600098,344.520630 478.381134,344.201202 
	C484.878967,343.035950 491.479248,342.442078 498.496429,341.717499 
	C497.737427,342.530182 496.593262,343.704163 495.296265,343.908691 
	C489.785522,344.777618 484.192932,345.153076 478.704681,346.127960 
	C475.729340,346.656464 473.981903,348.507446 475.816193,351.884735 
	C476.326996,352.825195 477.092987,354.423828 476.706299,354.930908 
	C473.095123,359.666718 469.258270,364.230438 465.130615,368.817261 
z"/>
					<path fill="#EDF5D7" opacity="1.000000" stroke="none"
						d="
M560.998779,318.632874 
	C548.032166,308.541809 532.624390,306.115601 516.314636,305.295776 
	C521.010620,304.799408 526.117981,303.886017 531.082153,304.388245 
	C537.269531,305.014221 543.839478,305.775421 549.300110,308.424438 
	C553.635315,310.527527 559.827209,311.827576 560.998779,318.632874 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M383.963165,304.590973 
	C385.009155,303.342651 385.855377,301.809998 387.142944,300.905548 
	C389.776245,299.055817 390.823059,297.305817 390.634583,293.596283 
	C390.291077,286.835938 391.483307,279.997528 392.423279,273.554138 
	C392.898132,279.621521 392.984467,285.326538 393.010681,291.421570 
	C392.993530,292.863068 393.036530,293.914551 392.967346,295.379730 
	C392.706177,298.581268 392.557190,301.369110 392.291626,304.206360 
	C392.175049,304.255798 392.096283,304.496490 391.696167,304.458557 
	C388.851776,304.477417 386.407471,304.534210 383.963165,304.590973 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M490.701294,178.940765 
	C488.544952,177.812180 486.527222,176.297256 484.007812,174.764893 
	C483.341064,174.685989 483.176025,174.624557 482.930359,174.262207 
	C481.263092,173.569366 479.676422,173.177444 477.663391,172.795959 
	C475.816010,172.850601 474.394989,172.894791 472.760010,172.695862 
	C469.694855,173.783783 466.843719,175.114838 463.731384,176.717194 
	C461.528595,178.701080 459.625610,180.660309 457.002747,177.745148 
	C457.007019,177.317245 457.007996,177.146072 457.008942,176.974915 
	C460.345581,175.163025 463.619629,173.222305 467.031647,171.565689 
	C474.455872,167.961044 481.484955,170.471146 488.228241,173.600266 
	C489.575073,174.225250 489.993408,176.851181 490.701294,178.940765 
z"/>
					<path fill="#80A230" opacity="1.000000" stroke="none"
						d="
M366.238312,304.882996 
	C366.961395,304.906494 367.684479,304.929962 368.747162,304.825439 
	C369.086761,304.697418 369.055481,304.927399 369.107452,305.189270 
	C369.440857,305.340057 369.722321,305.228912 370.003754,305.117798 
	C372.046631,305.057037 374.089539,304.996277 376.866455,305.279114 
	C381.399536,305.746613 385.198547,305.870453 388.944702,306.387634 
	C388.080902,307.496155 387.254456,308.846619 386.461517,308.827179 
	C379.384674,308.653961 372.306641,308.329437 365.250732,307.773132 
	C364.432251,307.708618 363.746216,305.964294 363.403656,304.992615 
	C364.618469,304.951965 365.428375,304.917480 366.238312,304.882996 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M408.169525,207.373779 
	C412.077087,206.843140 413.825958,208.756180 413.253448,212.800934 
	C413.207214,213.127579 413.240387,213.465637 413.238403,213.798386 
	C413.167511,225.688049 413.167572,225.688049 401.441345,229.109192 
	C402.976013,227.740509 404.728607,225.757904 406.874695,225.138382 
	C409.699982,224.322754 411.373444,223.315338 411.017090,220.179184 
	C410.534180,215.929260 412.611053,211.132004 408.169525,207.373779 
z"/>
					<path fill="#EDF5D7" opacity="1.000000" stroke="none"
						d="
M481.987549,304.359131 
	C476.990265,304.752930 471.915466,304.805176 466.379883,304.742218 
	C466.099396,304.002167 466.077301,303.098175 466.492432,302.797180 
	C469.511475,300.608276 478.971771,301.419006 481.987549,304.359131 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M578.048279,416.852600 
	C578.381470,413.500336 579.133057,410.202332 579.885254,406.468567 
	C581.115845,407.593781 583.591736,409.438049 583.314758,410.656464 
	C582.750549,413.138123 583.354675,417.471558 578.048279,416.852600 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M394.048096,251.208069 
	C392.749969,251.804337 391.435394,252.054581 389.726440,252.330597 
	C389.332092,250.059845 389.332092,247.763306 389.332092,245.466782 
	C389.966125,245.335297 390.600128,245.203812 391.234161,245.072311 
	C392.166626,247.002228 393.099121,248.932129 394.048096,251.208069 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M393.070801,291.031525 
	C392.984467,285.326538 392.898132,279.621521 392.790558,273.448242 
	C392.982574,272.714172 393.195831,272.448425 393.619293,272.037292 
	C394.457397,272.005188 395.085358,272.118469 395.828369,272.636963 
	C395.537079,274.036377 394.896088,275.000488 394.764221,276.029816 
	C394.124786,281.020782 393.622009,286.029266 393.070801,291.031525 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M388.997589,305.994324 
	C385.198547,305.870453 381.399536,305.746613 377.300720,305.342957 
	C379.034668,304.955444 381.068390,304.847687 383.532623,304.665466 
	C386.407471,304.534210 388.851776,304.477417 391.591370,304.752747 
	C390.923615,305.388031 389.960602,305.691162 388.997589,305.994324 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M393.554474,235.057495 
	C394.537415,236.420761 395.173523,237.855331 395.922821,239.612793 
	C395.012787,239.887848 393.989594,239.840027 392.966400,239.792206 
	C393.046814,238.237747 393.127228,236.683273 393.554474,235.057495 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M461.316528,372.982666 
	C462.016632,371.659943 463.103882,370.354523 464.486694,368.930267 
	C463.756073,370.207611 462.729889,371.603760 461.316528,372.982666 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M398.819122,229.801636 
	C398.200989,230.644775 397.381989,231.215561 396.263245,231.854385 
	C396.848419,231.124710 397.733307,230.327011 398.819122,229.801636 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M462.933655,255.009094 
	C462.047638,254.632065 461.335480,253.918503 460.564331,252.920776 
	C461.424225,252.574692 462.343048,252.512756 463.261871,252.450836 
	C463.210419,253.191406 463.158966,253.931992 462.933655,255.009094 
z"/>
					<path fill="#EDF5D7" opacity="1.000000" stroke="none"
						d="
M507.983246,304.511139 
	C507.263123,304.881592 506.477417,304.943512 505.317291,304.869690 
	C505.934448,304.556824 506.926086,304.379730 507.983246,304.511139 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M565.445557,323.102478 
	C564.546326,322.823792 563.847595,322.203888 563.053467,321.302551 
	C563.854004,321.601135 564.750000,322.181183 565.445557,323.102478 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M388.916992,252.238815 
	C388.764343,252.651230 388.270508,252.967407 387.465332,253.318695 
	C387.627899,252.950058 388.101868,252.546310 388.916992,252.238815 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M369.740967,305.036438 
	C369.722321,305.228912 369.440857,305.340057 369.059509,305.294952 
	C369.132477,305.077545 369.305328,305.016327 369.740967,305.036438 
z"/>
					<path fill="#EDF5D7" opacity="1.000000" stroke="none"
						d="
M513.927734,304.845520 
	C513.833069,305.055603 513.658508,305.093750 513.234131,305.034027 
	C513.272156,304.848633 513.559937,304.761139 513.927734,304.845520 
z"/>
					<path fill="#EDF5D7" opacity="1.000000" stroke="none"
						d="
M456.782959,176.948181 
	C457.007996,177.146072 457.007019,177.317245 456.769867,177.683289 
	C456.230316,177.740417 455.995880,177.526947 455.830444,177.237747 
	C455.830444,177.237747 455.964813,177.033417 456.037598,176.933350 
	C456.110413,176.833267 456.556946,176.921432 456.782959,176.948181 
z"/>
					<path fill="#FEFEFD" opacity="1.000000" stroke="none"
						d="
M192.986969,669.998108 
	C192.913696,646.639465 192.840408,623.280884 193.041656,599.382812 
	C193.383652,598.731812 193.533325,598.555725 193.504868,598.519531 
	C193.353592,598.326721 193.157669,598.168823 192.977173,597.998901 
	C192.923203,597.552917 192.869232,597.106934 193.351440,596.333008 
	C197.118393,596.240417 200.358109,596.710449 203.575317,596.588257 
	C204.757294,596.543335 205.886093,595.097961 207.484436,594.213379 
	C210.940155,594.204346 213.950607,594.273254 217.022797,594.623413 
	C217.750458,594.825378 218.416382,594.746094 219.409470,594.668030 
	C220.157349,594.779541 220.578064,594.889771 221.039810,595.373535 
	C224.156250,596.778076 227.231659,597.809143 230.656311,598.852783 
	C234.752579,602.073059 238.499619,605.280762 242.254852,608.865234 
	C242.911774,612.346619 240.908829,616.990479 246.940063,617.369751 
	C247.386581,618.791870 247.690552,619.897583 247.707581,621.279968 
	C247.696686,629.510437 247.972733,637.464172 248.248383,645.763794 
	C247.797256,647.396851 247.346497,648.684082 246.547455,650.016357 
	C242.059204,652.566162 241.509186,656.776855 240.917206,661.236450 
	C240.672043,661.770813 240.424225,661.971130 239.807419,661.926392 
	C238.551193,661.549805 236.983917,660.820312 236.704239,661.157837 
	C234.649948,663.636963 232.853287,666.329529 230.790604,669.176392 
	C230.067139,669.575745 229.528519,669.753540 228.748459,669.671021 
	C227.406967,670.072266 226.306900,670.733765 224.931992,671.580811 
	C223.423737,672.138977 222.190308,672.511536 220.956879,672.884094 
	C215.610764,672.937500 210.264648,672.990845 204.239822,672.727661 
	C202.376572,672.568848 201.192017,672.726562 200.007462,672.884277 
	C198.934540,672.925537 197.861603,672.966797 196.252472,672.663452 
	C194.806488,671.545288 193.896729,670.771729 192.986969,669.998108 
z"/>
					<path fill="#91AA55" opacity="1.000000" stroke="none"
						d="
M247.994537,621.003296 
	C247.690552,619.897583 247.386581,618.791870 246.985596,617.010010 
	C245.341278,613.718750 243.793976,611.103638 242.246643,608.488525 
	C238.499619,605.280762 234.752579,602.073059 230.556458,598.498413 
	C227.071167,597.087646 224.034973,596.043823 220.998779,595.000000 
	C220.578064,594.889771 220.157349,594.779541 219.192307,594.478943 
	C218.085663,594.306458 217.523361,594.324341 216.961044,594.342285 
	C213.950607,594.273254 210.940155,594.204346 207.028809,594.099609 
	C201.758514,594.046509 197.389160,594.029175 193.019791,594.011902 
	C192.748642,593.662903 192.477493,593.313965 192.206345,592.964966 
	C193.479004,592.661926 194.759140,592.069702 196.023132,592.102417 
	C204.963364,592.333435 213.931290,592.338684 222.828842,593.118408 
	C230.527451,593.793152 236.535294,597.849365 241.586136,603.859253 
	C255.845123,620.825745 254.428421,638.915222 246.057953,657.627502 
	C245.458405,658.967834 243.968536,659.909912 242.460175,661.030151 
	C242.027725,661.023071 241.869598,660.870239 241.963135,660.538818 
	C243.669693,656.795349 245.282715,653.383362 246.895737,649.971375 
	C247.346497,648.684082 247.797256,647.396851 248.482147,645.423340 
	C250.275818,636.771118 250.820251,628.835938 247.994537,621.003296 
z"/>
					<path fill="#91AA55" opacity="1.000000" stroke="none"
						d="
M240.092529,662.067627 
	C240.424225,661.971130 240.672043,661.770813 241.137238,661.216675 
	C241.438492,660.966614 241.869598,660.870239 241.869598,660.870239 
	C241.869598,660.870239 242.027725,661.023071 242.109772,661.098938 
	C241.497406,667.936157 235.612106,669.422180 230.897675,671.686584 
	C228.105423,673.027832 224.615356,672.916443 221.197968,673.171143 
	C222.190308,672.511536 223.423737,672.138977 225.281265,671.547119 
	C226.933548,670.862305 227.961716,670.396851 228.989883,669.931335 
	C229.528519,669.753540 230.067139,669.575745 231.144562,669.177124 
	C234.486420,666.660095 237.289474,664.363892 240.092529,662.067627 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M192.955872,594.343506 
	C197.389160,594.029175 201.758514,594.046509 206.583511,594.177612 
	C205.886093,595.097961 204.757294,596.543335 203.575317,596.588257 
	C200.358109,596.710449 197.118393,596.240417 193.432785,596.004028 
	C192.949295,595.560303 192.920624,595.117676 192.955872,594.343506 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M192.893188,670.385742 
	C193.896729,670.771729 194.806488,671.545288 195.856232,672.585083 
	C194.930603,672.158569 193.865021,671.466003 192.893188,670.385742 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M200.396545,672.996338 
	C201.192017,672.726562 202.376572,672.568848 203.776520,672.656128 
	C202.923157,672.970154 201.854401,673.039246 200.396545,672.996338 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M192.891281,598.247314 
	C193.157669,598.168823 193.353592,598.326721 193.504868,598.519531 
	C193.533325,598.555725 193.383652,598.731812 193.134979,598.921570 
	C192.904312,598.831787 192.854843,598.663696 192.891281,598.247314 
z"/>
					<path fill="#F4FCFC" opacity="1.000000" stroke="none"
						d="
M622.465149,676.689087 
	C616.214600,677.934143 610.515076,678.010315 605.449890,674.189453 
	C597.294128,668.037415 597.818298,656.838928 606.914001,652.080505 
	C616.684937,646.968933 626.867859,648.433105 636.796204,652.058044 
	C637.798828,652.424133 638.657043,654.251831 638.879089,655.524048 
	C641.021423,667.795715 632.704895,674.077332 623.820007,676.308533 
	C623.498291,676.389404 623.180298,676.485291 622.465149,676.689087 
z"/>
					<path fill="#47ADEF" opacity="1.000000" stroke="none"
						d="
M754.080444,652.427490 
	C753.174744,657.616028 752.817566,662.590820 751.130676,667.064331 
	C748.376587,674.367981 742.424988,679.533081 736.274475,683.772339 
	C728.184631,689.348267 714.318359,690.486023 705.528748,682.245667 
	C702.746887,679.637634 699.916016,677.078796 697.037903,674.577576 
	C694.058411,671.988159 692.072327,672.496948 690.848938,676.226624 
	C690.342041,677.771973 690.047729,679.458984 690.038025,681.083923 
	C689.969299,692.600769 690.072998,704.119202 689.906128,715.634094 
	C689.885193,717.075806 688.479858,719.769836 687.926392,719.716125 
	C685.207520,719.452148 680.972839,719.184082 680.228210,717.561523 
	C678.824585,714.503174 679.247070,710.507080 679.397461,706.921204 
	C679.426758,706.222778 681.859985,705.824036 682.850952,704.921021 
	C684.377014,703.530457 686.777283,701.804199 686.751465,700.254578 
	C686.681213,696.030151 685.560974,691.818848 684.808044,687.611084 
	C683.751709,681.707703 682.363403,675.825317 687.496765,670.831299 
	C688.407715,669.945007 688.931152,668.144714 688.833862,666.825500 
	C688.367554,660.503113 687.452209,654.211975 687.053894,647.887207 
	C686.845825,644.584045 686.045044,642.383362 682.713562,640.865051 
	C681.036743,640.100830 679.258667,637.502258 679.173279,635.657043 
	C678.793335,627.442322 679.008423,619.200134 679.008423,610.487915 
	C681.309387,610.487915 683.313171,610.487915 685.737244,610.487915 
	C684.957642,617.274475 689.434082,620.457642 692.822632,622.958496 
	C695.829224,620.440491 698.011780,618.601746 700.207886,616.779175 
	C700.970398,616.146362 701.979370,615.699524 702.513245,614.919617 
	C707.396423,607.786255 715.053345,608.813904 722.016602,608.623779 
	C729.701355,608.413940 736.053040,611.843506 740.959167,617.861084 
	C743.701294,621.224426 746.447510,624.600586 749.419312,627.756897 
	C754.097595,632.725464 754.149353,638.800964 754.016724,644.993896 
	C753.966797,647.324341 754.023682,649.657043 754.080444,652.427490 
M697.548157,668.386719 
	C705.449036,678.774536 720.752930,679.673767 730.053101,673.350281 
	C733.662842,670.895813 738.120972,668.444946 736.890137,662.329834 
	C736.890137,662.329834 737.061401,662.054321 737.780029,661.943237 
	C738.216797,661.712341 738.794250,661.590393 739.069763,661.234558 
	C745.350769,653.122864 741.192139,630.570190 732.416077,625.416382 
	C731.665405,624.975525 730.489319,625.259155 729.232727,625.203552 
	C729.232727,625.203552 729.017517,625.027344 728.994202,624.274780 
	C728.034058,620.029907 724.135376,620.660583 721.244873,619.447998 
	C710.274536,614.846191 695.499268,622.426819 692.313049,632.561096 
	C688.811646,643.697876 687.820984,655.356934 695.097046,666.613098 
	C695.732300,667.072937 696.367615,667.532776 697.548157,668.386719 
z"/>
					<path fill="#7DA325" opacity="1.000000" stroke="none"
						d="
M435.911743,619.531372 
	C434.402252,620.819153 433.235260,622.229736 431.284882,624.587158 
	C430.406464,622.408936 428.821198,620.401855 429.299683,619.258484 
	C430.255341,616.975098 432.093658,614.866760 434.035278,613.246887 
	C435.852325,611.730957 438.169006,610.496643 440.459137,609.953125 
	C442.803619,609.396790 445.382904,609.829834 447.125671,609.829834 
	C449.702942,609.104919 451.688293,608.055908 453.656250,608.087524 
	C458.947357,608.172607 464.467529,607.882812 469.457916,609.293823 
	C475.737885,611.069519 481.057404,614.803589 484.576355,620.922791 
	C489.229828,629.014648 490.246643,637.657776 490.041290,646.678894 
	C489.954102,650.509338 490.427582,654.395386 489.940460,658.164917 
	C488.950470,665.825623 491.592651,672.781677 493.530640,679.877075 
	C494.407166,683.086121 494.170532,685.509583 490.652191,686.628479 
	C488.032745,687.461548 481.664337,684.524109 481.027679,682.284729 
	C478.949158,674.973633 476.333313,674.148621 470.459808,679.135864 
	C462.724335,685.704163 454.018341,689.482300 443.739075,687.976379 
	C434.328674,686.597595 427.020752,681.206726 423.210114,672.855652 
	C419.860382,665.514893 421.819458,658.254456 427.496033,651.743774 
	C435.203735,642.903503 444.777069,640.774414 455.706024,641.704407 
	C461.150940,642.167786 466.596252,642.746155 472.052490,642.916931 
	C475.926544,643.038147 478.793396,640.003357 477.813416,636.559265 
	C475.797363,629.473877 474.083984,622.341980 466.332245,618.412781 
	C458.441010,614.412903 451.035675,615.876831 443.331787,618.125305 
	C441.020691,618.799805 438.616028,619.153442 435.911743,619.531372 
M474.809875,659.429749 
	C474.256073,656.769287 476.600769,653.056641 472.353546,651.726929 
	C462.308502,648.582092 452.226013,646.857971 442.371521,652.393982 
	C436.419189,655.737915 434.256287,662.407654 436.954834,668.708069 
	C439.857147,675.484131 446.270874,678.264465 456.405975,677.140076 
	C466.989685,675.965881 474.587616,669.017029 474.809875,659.429749 
z"/>
					<path fill="#7DA325" opacity="1.000000" stroke="none"
						d="
M294.654236,686.412231 
	C283.321991,681.453125 277.185425,671.227600 280.286102,659.929199 
	C281.859253,654.196960 286.668823,649.567993 291.998779,646.092529 
	C301.240509,640.066528 311.335266,640.200745 321.637604,641.739685 
	C324.339691,642.143311 327.132782,643.673340 329.620789,643.225037 
	C331.608490,642.866760 334.549469,640.438782 334.743195,638.674316 
	C336.049988,626.770508 328.133972,616.456665 314.992432,615.982666 
	C302.552338,615.533936 300.407593,617.113647 290.130646,623.637512 
	C285.251282,618.592651 286.019745,617.684265 291.526733,613.996338 
	C302.929443,606.360107 315.160553,607.449524 327.519714,609.171387 
	C335.101135,610.227661 346.269348,620.535461 346.909637,630.036194 
	C347.656982,641.125183 347.736389,652.261658 347.988159,663.380249 
	C348.082855,667.562744 347.969788,671.481750 351.823578,674.595398 
	C354.333008,676.622986 353.459503,684.496094 350.954681,686.513672 
	C347.472229,689.318665 344.093536,688.667725 341.389801,685.383362 
	C339.636719,683.253723 338.116455,680.845886 336.938232,678.351868 
	C335.541443,675.395020 333.637817,675.468079 331.331360,676.823730 
	C325.422577,680.296692 319.890228,684.736328 313.574524,687.094971 
	C307.757172,689.267517 301.051300,690.879150 294.654236,686.412231 
M302.757172,650.974854 
	C295.382904,655.133728 293.021698,659.065430 294.029907,665.506714 
	C294.890625,671.005615 299.533447,676.554382 305.115631,676.820068 
	C310.621460,677.081970 316.549042,676.722534 321.676544,674.902710 
	C331.068146,671.569580 334.267517,665.253723 332.807617,655.381775 
	C332.586090,653.883606 330.865875,651.881897 329.416565,651.409912 
	C320.892853,648.634399 312.203430,647.831238 302.757172,650.974854 
z"/>
					<path fill="#7EA720" opacity="1.000000" stroke="none"
						d="
M377.017334,173.704681 
	C377.598114,173.450668 378.007080,173.556610 378.688782,174.164307 
	C378.986938,175.112091 379.012299,175.558136 379.129639,176.220413 
	C379.411072,176.706833 379.667358,176.875778 380.398193,176.958084 
	C381.894043,176.962158 382.982117,176.951599 384.070190,176.941025 
	C384.070190,176.941025 384.033813,177.000610 384.167542,177.322571 
	C386.244080,181.507889 391.666351,182.653351 395.255554,178.890717 
	C400.821564,173.055756 408.549774,172.782394 415.131226,171.453674 
	C426.558899,169.146530 438.419647,170.562241 449.118469,176.454391 
	C451.538727,177.787292 453.808136,179.399551 456.254028,180.678497 
	C457.873383,181.525238 460.408264,183.033951 461.255737,182.435913 
	C462.959839,181.233353 463.790497,178.793121 465.251404,176.730209 
	C466.256561,176.353516 466.991852,176.110229 468.108948,176.033997 
	C473.659088,176.467972 478.827423,176.734894 483.995758,177.001831 
	C485.957397,179.977325 488.444885,182.741516 489.746979,185.982025 
	C491.360565,189.997772 485.848907,195.460861 480.110168,196.006683 
	C478.624542,196.147995 477.086334,196.226929 475.620697,196.008957 
	C464.834442,194.404709 455.146515,198.349625 445.372009,201.881104 
	C444.590912,202.163315 443.867584,202.636703 443.071167,202.840210 
	C437.148682,204.353500 434.951935,206.760910 435.117340,212.837357 
	C435.240692,217.370682 435.709747,221.989777 436.863434,226.348755 
	C437.272522,227.894653 440.041473,229.045746 441.947876,229.895111 
	C443.561340,230.613998 445.776764,230.175201 447.202789,231.076324 
	C449.370575,232.446182 451.085999,234.531891 452.993561,236.313538 
	C450.676270,237.888550 448.490875,240.532074 446.016907,240.837418 
	C438.725769,241.737274 431.312042,242.094528 423.961975,241.936295 
	C416.958466,241.785522 409.973816,240.726959 402.983521,240.040939 
	C402.333160,239.977127 401.559418,239.899429 401.088135,239.522018 
	C399.713959,238.421539 398.464111,237.165802 397.165527,235.970947 
	C398.243683,234.894318 399.156403,233.511505 400.441193,232.817307 
	C402.108612,231.916397 404.053833,231.496384 405.912201,230.989243 
	C409.512970,230.006622 413.136566,229.107697 415.974335,228.374634 
	C415.974335,222.025116 415.723999,216.746490 416.051025,211.503860 
	C416.328461,207.055954 414.110291,205.393906 410.287872,204.200638 
	C402.782074,201.857498 395.444305,198.976807 387.941162,196.624161 
	C386.037140,196.027145 383.620575,196.091949 381.701721,196.691666 
	C375.086395,198.759186 368.314728,199.128326 362.617859,195.367279 
	C360.046936,193.669952 358.308044,188.996674 358.228851,185.625763 
	C358.095978,179.968002 362.181152,174.292038 369.133667,174.167206 
	C370.102783,173.967255 370.544281,173.953659 370.985809,173.940063 
	C372.939056,173.981598 374.892273,174.023117 377.017334,173.704681 
z"/>
					<path fill="#80A230" opacity="1.000000" stroke="none"
						d="
M483.995789,176.660446 
	C478.827423,176.734894 473.659088,176.467972 468.321655,175.749176 
	C469.759735,174.511215 471.366852,173.725098 472.973969,172.938995 
	C474.394989,172.894791 475.816010,172.850601 477.773499,173.137207 
	C479.876953,173.833038 481.443970,174.198090 483.010956,174.563126 
	C483.176025,174.624557 483.341064,174.685989 483.749207,174.850296 
	C483.993439,175.408463 483.994598,175.863754 483.995789,176.660446 
z"/>
					<path fill="#8AA44C" opacity="1.000000" stroke="none"
						d="
M370.838562,173.725159 
	C370.544281,173.953659 370.102783,173.967255 369.330200,173.962387 
	C369.563232,173.799362 370.127289,173.654800 370.838562,173.725159 
z"/>
					<path fill="#7BA51D" opacity="1.000000" stroke="none"
						d="
M388.944702,306.387634 
	C389.960602,305.691162 390.923615,305.388031 391.991455,304.790680 
	C392.096283,304.496490 392.175049,304.255798 392.633118,304.052948 
	C393.087280,300.888733 393.083405,297.927368 393.079529,294.966003 
	C393.036530,293.914551 392.993530,292.863068 393.010681,291.421570 
	C393.622009,286.029266 394.124786,281.020782 394.764221,276.029816 
	C394.896088,275.000488 395.537079,274.036377 396.041199,272.764648 
	C396.556091,272.425140 396.973175,272.363159 398.046448,272.575073 
	C404.800964,272.687256 410.899323,272.525543 416.997681,272.363831 
	C419.691681,272.326599 422.385651,272.289398 425.627563,272.458313 
	C426.460388,272.584625 426.745270,272.504791 427.030182,272.424927 
	C428.732269,272.388031 430.434326,272.351105 432.658478,272.514954 
	C433.180542,272.715729 433.635986,272.782196 433.635986,272.782196 
	C433.635986,272.782196 434.014526,272.513184 434.014526,272.513184 
	C434.456604,272.481842 434.898651,272.450470 435.770538,272.809540 
	C437.451843,273.198303 438.703339,273.196594 440.406921,273.161011 
	C441.517883,273.109192 442.176666,273.078857 442.835602,273.075226 
	C450.043304,273.035400 450.027405,273.036591 450.056396,280.009216 
	C450.086853,287.336426 450.152008,294.663513 449.974854,302.184509 
	C450.912292,303.171356 452.076904,303.964264 453.241516,304.757202 
	C453.241516,304.757202 453.256256,304.788757 453.250916,304.772858 
	C459.614349,307.095642 466.029510,308.748444 472.982666,308.238739 
	C477.220032,307.928101 481.533295,308.873047 485.815704,308.913727 
	C497.627167,309.025940 509.441437,309.060791 521.252563,308.936615 
	C535.266174,308.789307 547.910950,312.730591 559.175903,321.070740 
	C559.699402,321.458344 560.279419,321.862427 560.615540,322.395905 
	C563.951904,327.691040 567.694214,332.800049 570.368164,338.415710 
	C571.853577,341.535431 571.329407,345.557861 572.081787,349.094574 
	C573.008179,353.449951 571.656616,358.483429 575.704529,361.977203 
	C575.909058,362.153748 575.946289,362.594025 575.957214,362.915039 
	C576.302246,373.021881 576.786865,383.129333 576.776001,393.236176 
	C576.774658,394.501343 574.177795,396.815308 572.729736,396.859009 
	C562.257751,397.174652 551.768433,396.878418 541.292236,397.114288 
	C537.530884,397.199005 538.270447,394.445557 537.236389,391.840210 
	C536.983154,389.099091 536.994019,387.046967 537.366577,384.922852 
	C537.775757,382.920776 537.823303,380.990662 537.897095,378.619446 
	C537.888489,376.120117 537.853760,374.061920 537.869019,371.586243 
	C537.901123,368.229126 538.778748,364.686310 533.816101,365.077271 
	C533.583923,364.578888 533.351685,364.080536 533.415955,363.265259 
	C534.067505,362.296875 534.422668,361.645386 534.994690,360.846069 
	C534.470947,360.155579 533.730408,359.612885 532.967285,358.806152 
	C532.927734,358.366333 532.910767,358.190491 532.916016,357.635956 
	C531.650757,356.484375 530.363342,355.711456 529.075867,354.938568 
	C529.075867,354.938568 528.866394,354.712494 528.899658,354.143799 
	C528.930542,352.742859 528.928223,351.910614 528.904297,350.741638 
	C527.165527,349.079773 526.169312,345.238403 523.069519,348.739410 
	C522.737793,348.749054 522.467712,348.624786 522.136230,347.867676 
	C521.684814,346.895355 521.356384,346.422028 521.014282,345.578308 
	C520.463928,344.530945 519.927246,343.853943 519.390625,343.176910 
	C518.878784,343.918549 518.366943,344.660187 517.855103,345.401825 
	C517.316345,345.194855 516.777649,344.987915 516.028503,344.582336 
	C513.724915,342.611328 511.677887,339.387482 509.530426,339.319092 
	C500.488098,339.031189 491.387909,339.354309 482.364227,340.112335 
	C476.465363,340.607819 472.569183,343.308990 470.708466,350.140564 
	C467.329041,362.548431 459.672180,372.940063 447.359650,377.337952 
	C439.695465,380.075470 430.703583,382.221222 422.519745,376.857544 
	C417.908691,373.835449 413.004272,371.228027 408.603027,367.937500 
	C407.157257,366.856598 406.213837,364.223480 406.191772,362.287079 
	C406.130707,356.931305 403.496063,353.103485 398.382660,353.130096 
	C389.257904,353.177551 380.121155,353.523712 371.022797,354.196655 
	C368.764374,354.363647 366.269501,355.859802 364.523743,357.448669 
	C361.319763,360.364716 354.078217,362.610077 350.682770,360.562683 
	C349.308136,359.733765 348.059326,357.408630 348.070679,355.773529 
	C348.157257,343.314026 348.603821,330.857422 348.839142,318.398041 
	C348.869720,316.777832 348.310364,315.150513 348.065186,313.520355 
	C346.877441,305.622192 348.523193,303.875732 356.954681,304.955383 
	C359.195496,304.976196 360.613739,304.975555 362.273651,304.981354 
	C362.515350,304.987762 362.998779,304.998779 362.998779,304.998779 
	C363.746216,305.964294 364.432251,307.708618 365.250732,307.773132 
	C372.306641,308.329437 379.384674,308.653961 386.461517,308.827179 
	C387.254456,308.846619 388.080902,307.496155 388.944702,306.387634 
z"/>
					<path fill="#80A230" opacity="1.000000" stroke="none"
						d="
M362.031952,304.974915 
	C360.613739,304.975555 359.195496,304.976196 357.349304,304.949432 
	C357.805481,304.135071 358.689667,303.348145 359.782166,302.313721 
	C360.432098,302.035095 360.873749,302.003967 361.648773,302.406372 
	C361.998749,303.551605 362.015350,304.263245 362.031952,304.974915 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M537.818970,372.003723 
	C537.853760,374.061920 537.888489,376.120117 537.639221,378.886475 
	C537.238464,381.394684 537.121704,383.194763 537.004883,384.994812 
	C536.994019,387.046967 536.983154,389.099091 536.936401,391.592163 
	C536.234863,392.333832 535.569153,392.634521 534.630371,392.972595 
	C534.330872,384.950439 534.304504,376.890900 534.609009,368.502502 
	C535.899597,369.450378 536.859253,370.727051 537.818970,372.003723 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M535.949219,400.282043 
	C531.846375,401.339569 533.118469,398.625854 533.639282,396.235260 
	C534.534668,397.281982 535.212097,398.620605 535.949219,400.282043 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M450.201996,301.990631 
	C450.152008,294.663513 450.086853,287.336426 450.056396,280.009216 
	C450.027405,273.036591 450.043304,273.035400 442.835602,273.075226 
	C442.176666,273.078857 441.517883,273.109192 440.397003,272.983704 
	C439.934998,272.840240 439.929291,272.488159 439.929291,272.488159 
	C452.922363,270.711304 452.941315,270.711395 452.874725,283.623016 
	C452.844696,289.446320 452.722137,295.269135 452.250000,301.546692 
	C451.306274,301.997711 450.754120,301.994171 450.201996,301.990631 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M416.544312,272.238770 
	C410.899323,272.525543 404.800964,272.687256 398.361084,272.628143 
	C404.043365,272.309418 410.067169,272.211548 416.544312,272.238770 
z"/>
					<path fill="#8AA44C" opacity="1.000000" stroke="none"
						d="
M449.974854,302.184509 
	C450.754120,301.994171 451.306274,301.997711 452.134155,301.999939 
	C452.640106,302.683075 452.870331,303.367462 453.171021,304.404541 
	C452.076904,303.964264 450.912292,303.171356 449.974854,302.184509 
z"/>
					<path fill="#80A230" opacity="1.000000" stroke="none"
						d="
M439.535889,272.394958 
	C439.929291,272.488159 439.934998,272.840240 439.944916,273.017578 
	C438.703339,273.196594 437.451843,273.198303 436.084106,272.925873 
	C437.026062,272.535095 438.084290,272.418457 439.535889,272.394958 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M433.757690,272.418030 
	C434.014526,272.513184 433.635986,272.782196 433.635986,272.782196 
	C433.635986,272.782196 433.180542,272.715729 433.077118,272.593658 
	C433.149445,272.421997 433.325165,272.372437 433.757690,272.418030 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M426.765411,272.337280 
	C426.745270,272.504791 426.460388,272.584625 426.069977,272.533936 
	C426.143219,272.352081 426.321930,272.300812 426.765411,272.337280 
z"/>
					<path fill="#8AA44C" opacity="1.000000" stroke="none"
						d="
M537.869019,371.586243 
	C536.859253,370.727051 535.899597,369.450378 534.623413,368.090881 
	C534.124878,367.281342 533.942810,366.554626 533.788452,365.452606 
	C538.778748,364.686310 537.901123,368.229126 537.869019,371.586243 
z"/>
					<path fill="#80A230" opacity="1.000000" stroke="none"
						d="
M523.177917,349.022278 
	C526.169312,345.238403 527.165527,349.079773 528.499756,350.722778 
	C527.302490,351.080780 526.488037,351.120850 525.673645,351.160889 
	C524.877869,350.542297 524.082092,349.923737 523.177917,349.022278 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M534.777832,360.993896 
	C534.422668,361.645386 534.067505,362.296875 533.403442,362.985199 
	C532.354858,361.608337 531.615234,360.194672 531.162476,358.410645 
	C531.930786,358.031738 532.412292,358.023193 532.893799,358.014648 
	C532.910767,358.190491 532.927734,358.366333 533.007263,359.102661 
	C533.639160,360.106720 534.208496,360.550323 534.777832,360.993896 
z"/>
					<path fill="#8AA44C" opacity="1.000000" stroke="none"
						d="
M532.916016,357.635956 
	C532.412292,358.023193 531.930786,358.031738 531.086670,358.066589 
	C530.170349,357.250305 529.616699,356.407806 529.069458,355.251923 
	C530.363342,355.711456 531.650757,356.484375 532.916016,357.635956 
z"/>
					<path fill="#80A230" opacity="1.000000" stroke="none"
						d="
M517.983521,345.607574 
	C518.366943,344.660187 518.878784,343.918549 519.390625,343.176910 
	C519.927246,343.853943 520.463928,344.530945 520.618774,345.649719 
	C519.979126,346.163208 519.721313,346.234985 519.463501,346.306732 
	C519.013000,346.142273 518.562500,345.977783 517.983521,345.607574 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M525.786926,351.472809 
	C526.488037,351.120850 527.302490,351.080780 528.521362,351.059570 
	C528.928223,351.910614 528.930542,352.742859 528.874695,353.991760 
	C527.844421,353.533844 526.872314,352.659271 525.786926,351.472809 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M519.628418,346.584961 
	C519.721313,346.234985 519.979126,346.163208 520.632446,346.020081 
	C521.356384,346.422028 521.684814,346.895355 522.021851,347.668213 
	C521.284790,347.599518 520.539062,347.231354 519.628418,346.584961 
z"/>
					<path fill="#47ADEF" opacity="1.000000" stroke="none"
						d="
M548.309937,444.627045 
	C549.960449,440.883545 551.387817,437.474670 552.954102,434.130859 
	C553.736328,432.460968 554.772461,430.909973 555.693726,429.305206 
	C557.311340,430.582123 559.522400,431.536926 560.425903,433.201447 
	C562.456543,436.942383 563.889404,441.015167 565.468384,444.991486 
	C566.754944,448.231323 563.009460,455.271179 559.322998,456.721069 
	C554.932251,458.447937 551.628174,456.821014 549.934082,451.899841 
	C549.161804,449.656555 548.730225,447.295929 548.309937,444.627045 
z"/>
					<path fill="#8AA44C" opacity="1.000000" stroke="none"
						d="
M464.981567,176.863586 
	C463.790497,178.793121 462.959839,181.233353 461.255737,182.435913 
	C460.408264,183.033951 457.873383,181.525238 456.254028,180.678497 
	C453.808136,179.399551 451.538727,177.787292 449.118469,176.454391 
	C438.419647,170.562241 426.558899,169.146530 415.131226,171.453674 
	C408.549774,172.782394 400.821564,173.055756 395.255554,178.890717 
	C391.666351,182.653351 386.244080,181.507889 384.163513,177.287918 
	C387.901520,179.304245 390.919312,176.661011 394.083710,175.148026 
	C408.789398,168.116745 424.080353,165.528580 439.938324,170.086182 
	C445.239105,171.609634 450.080383,174.731888 455.481079,177.180527 
	C455.995880,177.526947 456.230316,177.740417 456.766541,177.940018 
	C459.625610,180.660309 461.528595,178.701080 463.982910,176.812027 
	C464.657562,176.711563 464.819550,176.787582 464.981567,176.863586 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M383.893677,176.657043 
	C382.982117,176.951599 381.894043,176.962158 380.293518,176.752853 
	C379.589996,176.276962 379.342194,176.100693 379.037659,176.004181 
	C379.012299,175.558136 378.986938,175.112091 378.962769,174.330719 
	C380.548370,174.787949 382.132782,175.580505 383.893677,176.657043 
z"/>
					<path fill="#7DA325" opacity="1.000000" stroke="none"
						d="
M551.006409,407.962219 
	C558.499634,407.962494 565.494263,408.000763 572.488159,407.944000 
	C575.166565,407.922241 576.987610,408.502106 577.059082,411.741852 
	C577.132568,415.071930 575.571411,416.093231 572.488892,416.069519 
	C561.831482,415.987488 551.171448,415.927979 540.515869,416.088257 
	C536.580139,416.147491 535.621338,413.396027 535.413757,410.711212 
	C535.353516,409.932495 538.646545,408.340759 540.531921,408.087952 
	C543.803894,407.649323 547.176941,407.963684 551.006409,407.962219 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M362.273651,304.981323 
	C362.015350,304.263245 361.998749,303.551605 361.973083,302.484314 
	C363.297455,302.832397 364.630890,303.536133 366.101318,304.561401 
	C365.428375,304.917480 364.618469,304.951965 363.403656,304.992615 
	C362.998779,304.998779 362.515350,304.987762 362.273651,304.981323 
z"/>
					<path fill="#8DA550" opacity="1.000000" stroke="none"
						d="
M392.967316,295.379730 
	C393.083405,297.927368 393.087280,300.888733 392.749695,304.003510 
	C392.557190,301.369110 392.706177,298.581268 392.967316,295.379730 
z"/>
					<path fill="#EDF5D7" opacity="1.000000" stroke="none"
						d="
M465.251404,176.730194 
	C464.819550,176.787582 464.657562,176.711563 464.244080,176.540726 
	C466.843719,175.114838 469.694855,173.783783 472.760010,172.695862 
	C471.366852,173.725098 469.759735,174.511215 467.939880,175.582123 
	C466.991852,176.110229 466.256561,176.353516 465.251404,176.730194 
z"/>
					<path fill="#EDF5D7" opacity="1.000000" stroke="none"
						d="
M482.930359,174.262207 
	C481.443970,174.198090 479.876953,173.833038 478.199890,173.126770 
	C479.676422,173.177444 481.263092,173.569366 482.930359,174.262207 
z"/>
					<path fill="#EDF5D7" opacity="1.000000" stroke="none"
						d="
M247.707581,621.279968 
	C250.820251,628.835938 250.275818,636.771118 248.482544,645.077515 
	C247.972733,637.464172 247.696686,629.510437 247.707581,621.279968 
z"/>
					<path fill="#EDF5D7" opacity="1.000000" stroke="none"
						d="
M239.807434,661.926392 
	C237.289474,664.363892 234.486420,666.660095 231.329407,668.955566 
	C232.853287,666.329529 234.649948,663.636963 236.704239,661.157837 
	C236.983917,660.820312 238.551193,661.549805 239.807434,661.926392 
z"/>
					<path fill="#EDF5D7" opacity="1.000000" stroke="none"
						d="
M241.963135,660.538818 
	C241.869598,660.870239 241.438492,660.966614 241.218460,660.986389 
	C241.509186,656.776855 242.059204,652.566162 246.547455,650.016357 
	C245.282715,653.383362 243.669693,656.795349 241.963135,660.538818 
z"/>
					<path fill="#EDF5D7" opacity="1.000000" stroke="none"
						d="
M242.254868,608.865234 
	C243.793976,611.103638 245.341278,613.718750 246.843048,616.693604 
	C240.908829,616.990479 242.911774,612.346619 242.254868,608.865234 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M221.039810,595.373535 
	C224.034973,596.043823 227.071167,597.087646 230.207214,598.485840 
	C227.231659,597.809143 224.156250,596.778076 221.039810,595.373535 
z"/>
					<path fill="#EDF5D7" opacity="1.000000" stroke="none"
						d="
M228.748459,669.671021 
	C227.961716,670.396851 226.933548,670.862305 225.556107,671.361511 
	C226.306900,670.733765 227.406967,670.072266 228.748459,669.671021 
z"/>
					<path fill="#F2F9DE" opacity="1.000000" stroke="none"
						d="
M217.022797,594.623474 
	C217.523361,594.324341 218.085663,594.306458 218.865143,594.477661 
	C218.416382,594.746094 217.750458,594.825378 217.022797,594.623474 
z"/>
					<path fill="#F9FDFD" opacity="1.000000" stroke="none"
						d="
M737.061401,662.054321 
	C737.061401,662.054321 736.890137,662.329834 736.600220,662.565430 
	C736.122803,662.846191 735.935303,662.891357 735.426147,663.003296 
	C732.730591,665.940796 730.356689,668.811646 727.692932,671.924194 
	C718.068787,677.112549 709.158691,676.055847 701.442017,669.134705 
	C700.103516,667.934265 698.640015,666.873230 697.122437,665.410156 
	C693.483643,660.120300 692.479126,654.589417 692.958435,648.611328 
	C693.208923,645.486206 692.674377,642.281433 693.084229,639.190857 
	C693.441345,636.497681 694.013428,633.462341 695.559082,631.366089 
	C700.423950,624.768616 707.235962,620.805969 715.483765,621.210632 
	C720.046509,621.434509 724.509521,623.688477 729.017517,625.027344 
	C729.017517,625.027344 729.232727,625.203552 729.520752,625.524170 
	C735.012512,631.435608 739.480530,637.644653 739.498169,645.502319 
	C739.510559,651.017822 737.930359,656.536865 737.061401,662.054321 
z"/>
					<path fill="#69B1DD" opacity="1.000000" stroke="none"
						d="
M729.005859,624.651001 
	C724.509521,623.688477 720.046509,621.434509 715.483765,621.210632 
	C707.235962,620.805969 700.423950,624.768616 695.559082,631.366089 
	C694.013428,633.462341 693.441345,636.497681 693.084229,639.190857 
	C692.674377,642.281433 693.208923,645.486206 692.958435,648.611328 
	C692.479126,654.589417 693.483643,660.120300 697.045227,665.493042 
	C697.079773,665.913452 696.922668,666.076782 696.610779,666.047913 
	C695.881592,666.000000 695.464355,665.980896 695.047058,665.961853 
	C687.820984,655.356934 688.811646,643.697876 692.313049,632.561096 
	C695.499268,622.426819 710.274536,614.846191 721.244873,619.447998 
	C724.135376,620.660583 728.034058,620.029907 729.005859,624.651001 
z"/>
					<path fill="#69B1DD" opacity="1.000000" stroke="none"
						d="
M697.156982,665.830566 
	C698.640015,666.873230 700.103516,667.934265 701.442017,669.134705 
	C709.158691,676.055847 718.068787,677.112549 728.012939,671.880737 
	C730.997742,668.709229 733.372742,665.822876 735.747742,662.936584 
	C735.935303,662.891357 736.122803,662.846191 736.590576,662.727356 
	C738.120972,668.444946 733.662842,670.895813 730.053101,673.350281 
	C720.752930,679.673767 705.449036,678.774536 697.271484,667.869629 
	C696.970764,666.927307 696.946716,666.502075 696.922668,666.076782 
	C696.922668,666.076782 697.079773,665.913452 697.156982,665.830566 
z"/>
					<path fill="#69B1DD" opacity="1.000000" stroke="none"
						d="
M737.420654,661.998779 
	C737.930359,656.536865 739.510559,651.017822 739.498169,645.502319 
	C739.480530,637.644653 735.012512,631.435608 729.659790,625.525513 
	C730.489319,625.259155 731.665405,624.975525 732.416077,625.416382 
	C741.192139,630.570190 745.350769,653.122864 739.069763,661.234558 
	C738.794250,661.590393 738.216797,661.712341 737.420654,661.998779 
z"/>
					<path fill="#58AAE5" opacity="1.000000" stroke="none"
						d="
M696.610779,666.047913 
	C696.946716,666.502075 696.970764,666.927307 696.998840,667.672607 
	C696.367615,667.532776 695.732300,667.072937 695.072021,666.287476 
	C695.464355,665.980896 695.881592,666.000000 696.610779,666.047913 
z"/>
					<path fill="#F9FBF1" opacity="1.000000" stroke="none"
						d="
M474.803894,659.874573 
	C474.587616,669.017029 466.989685,675.965881 456.405975,677.140076 
	C446.270874,678.264465 439.857147,675.484131 436.954834,668.708069 
	C434.256287,662.407654 436.419189,655.737915 442.371521,652.393982 
	C452.226013,646.857971 462.308502,648.582092 472.353546,651.726929 
	C476.600769,653.056641 474.256073,656.769287 474.803894,659.874573 
z"/>
					<path fill="#F9FBF1" opacity="1.000000" stroke="none"
						d="
M303.109192,650.797913 
	C312.203430,647.831238 320.892853,648.634399 329.416565,651.409912 
	C330.865875,651.881897 332.586090,653.883606 332.807617,655.381775 
	C334.267517,665.253723 331.068146,671.569580 321.676544,674.902710 
	C316.549042,676.722534 310.621460,677.081970 305.115631,676.820068 
	C299.533447,676.554382 294.890625,671.005615 294.029907,665.506714 
	C293.021698,659.065430 295.382904,655.133728 303.109192,650.797913 
z"/>
					<path fill="#8AA44C" opacity="1.000000" stroke="none"
						d="
M379.129639,176.220413 
	C379.342194,176.100693 379.589996,176.276962 379.885712,176.738220 
	C379.667358,176.875778 379.411072,176.706833 379.129639,176.220413 
z"/>
					<path fill="#8AA44C" opacity="1.000000" stroke="none"
						d="
M537.366577,384.922852 
	C537.121704,383.194763 537.238464,381.394684 537.613037,379.327576 
	C537.823303,380.990662 537.775757,382.920776 537.366577,384.922852 
z"/>
					<path fill="#8AA44C" opacity="1.000000" stroke="none"
						d="
M534.994690,360.846069 
	C534.208496,360.550323 533.639160,360.106720 533.029785,359.366638 
	C533.730408,359.612885 534.470947,360.155579 534.994690,360.846069 
z"/>
					<path fill="#F4FCFC" opacity="1.000000" stroke="none"
						d="
M735.426147,663.003296 
	C733.372742,665.822876 730.997742,668.709229 728.302734,671.639038 
	C730.356689,668.811646 732.730591,665.940796 735.426147,663.003296 
z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
